@import "./variable.scss";
#form-file-upload {
    height: 16rem;
    width: 28rem;
    max-width: 100%;
    text-align: center;
    position: relative;
}

#input-file-upload {
    display: none;
}

#label-file-upload {
    border-radius: 10px;
    border: 1px solid var(--grey-light-color);
    background-color: var(--white-color);
}

#label-file-upload.drag-active {
    background-color: var(--white-color);
}

.upload-button {
    cursor: pointer;
    padding: 0.25rem;
    font-size: 1rem;
    border: none;
    font-family: var(--font-family-oswald);
    background-color: transparent;
}

.upload-button:hover {
    text-decoration-line: underline;
}

#drag-file-element {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}

.preview-img {
    width: 250px;
    height: 250px;
    object-fit: contain;
}

.preview-view {
    display: flex;
    justify-content: center;
    align-items: center;
}

.cross-pos {
    position: absolute;
    right: 0;
    top: 0;
}

.cross-bg {
    background-color: var(--theme-color);
    border-radius: 30px;
}

.cross-style {
    width: 30px;
    height: 30px;
    z-index: 9999999;
}

.img-drag-drop-text {
    font-size: 0.875rem;
    color: var(--black-color-light);
    font-family: var(--font-family-inter);
    ;
}