// --------------------Login CSS------------------------------
.boxes {
    width: fit-content;
}

.box-outsider-auth {
    padding-left: 6rem;
    padding-right: 6rem;
}

@media screen and (max-width: 768px) {
    .box-outsider-auth {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}

.login-box .logo {
    padding: 0px 40px;
    margin: 0px 50px;
}

.login-box h1 {
    font-family: var(--default-font-family-acme);
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 46px;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    margin-top: 24px;
    color: var(--black-color-dark);
}

.login-box p.subtitle,
.login-box .version-text {
    font-family: var(--font-family-mulish);
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    margin-top: 8px;
    line-height: 20px;
    text-align: center;
    color: var(--gray-color-dark);
}

.login-box .login-form {
    margin: 24px 0px;
}

.login-box .login-form input[type="email"],
.login-box .login-form input[type="text"],
.login-box .login-form input[type="password"],
.input-group-text {
    background: var(--white-color);
    border: 1px solid var(--grey-light-color);
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    font-family: var(--font-family-inter);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-top: 20px !important;
    padding: 10px 14px;
    color: var(--gray-color-dark);
    &:hover,
    &:focus {
        border: 1px solid var(--theme-color);
    }
}

.input-group {
    border-left: transparent !important;
}

.login-box .login-form .forgot-line {
    margin-top: 24px;
}

.login-box .login-form .forgot-line label {
    font-family: var(--font-family-mulish);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: var(--black-color-light);
}

.forgot-line a {
    display: flex;
    margin-left: auto;
    align-items: center;
    font-family: var(--font-family-inter);
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--theme-color);
}

.login-box .login-form .btnlogin {
    background: var(--theme-color);
    border: 1px solid var(--theme-color);
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    font-family: var(--font-family-inter);
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    padding: 16px 28px;
    color: var(--white-color);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 28px;
    gap: 8px;
    margin: 16px auto;
    &:hover,
    &:focus {
        background: var(--white-color);
        border: 1px solid var(--theme-color);
        color: var(--theme-color);
    }
}

.login-box p.signup-link {
    font-family: var(--font-family-mulish);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: var(--gray-color-dark);
    justify-content: center;
    margin-top: 8px;
    & a.nav-link {
        padding: 0px 5px;
        color: var(--theme-color);
    }
}

.login-box .login-form .form-check-input {
    cursor: pointer !important;
    &:checked {
        background-color: var(--theme-color) !important;
        border-color: var(--theme-color) !important;
    }
    &:focus {
        border-color: var(--light-pink-color);
        outline: 0;
        box-shadow: 0 0 0 0.25rem rgba(164, 91, 180, 25%);
    }
}

@media screen and (max-width:480px) {
    .boxes {
        width: auto;
    }
}

.box-bg {
    background-color: var(--white-color);
}

// @media screen and (max-width: 768px) {
//     .login-box .login-form {
//         margin: 24px 0px !important;
//     }
//     .login-box {
//         padding: 24px 30px !important;
//     }
// }