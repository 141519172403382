@import './variable.scss';
.customize-progress .MuiStepLabel-label {
    margin-top: -95px !important;
    color: var(--grey-light-color) !important;
    font-family: var(--font-family-mulish) bolder !important;
    font-size: 20px !important;
    line-height: 25px !important;
    text-transform: uppercase !important;
    // -webkit-text-stroke: thin !important; 
    font-weight: bolder !important;
}

.customize-progress .MuiStepLabel-label.Mui-completed,
.customize-progress .MuiStepLabel-label.Mui-active {
    color: var(--theme-color) !important;
    font-family: var(--font-family-mulish) bolder !important;
    font-size: 20px !important;
    line-height: 25px !important;
    text-transform: uppercase !important;
    // -webkit-text-stroke: thin !important;
    font-weight: bolder !important;
}

@media screen and (min-width:768px) and (max-width: 991px) {
    .customize-progress .MuiStepLabel-label {
        font-size: 12px !important;
        line-height: 25px !important;
        text-transform: uppercase !important;
    }
    .customize-progress .MuiStepLabel-label.Mui-completed,
    .customize-progress .MuiStepLabel-label.Mui-active {
        color: var(--theme-color) !important;
        font-family: var(--font-family-mulish) bold !important;
        font-size: 12px !important;
        line-height: 25px !important;
        text-transform: uppercase !important;
        // -webkit-text-stroke: thin !important;
    }
}

@media screen and (max-width:767px) {
    .customize-progress .MuiStepLabel-label {
        font-size: 12px !important;
        line-height: 15px !important;
        margin-top: 17px !important;
    }
    .customize-progress .MuiStepLabel-label.Mui-completed,
    .customize-progress .MuiStepLabel-label.Mui-active {
        font-size: 12px !important;
        line-height: 15px !important;
        font-weight: bolder !important;
    }
    //---------for circle--------------
    .customize-progress .css-i37urb,
    .customize-progress .css-1cg6war,
    .customize-progress .css-10vfrtr {
        width: 40px !important;
        height: 40px !important;
    }
    .customize-progress .MuiStepConnector-alternativeLabel {
        top: 15px !important;
    }
}

@media screen and (max-width:468px) {
    .customize-progress .MuiStepLabel-label {
        font-size: 8px !important;
        line-height: 15px !important;
    }
    .customize-progress .MuiStepLabel-label.Mui-completed,
    .customize-progress .MuiStepLabel-label.Mui-active {
        font-size: 8px !important;
        line-height: 15px !important;
        font-weight: bolder !important;
    }
}

@media screen and (max-width: 767px) {
    .customize-progress   .css-n7tliy-MuiStep-root {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .customize-progress .MuiStepConnector-root {
        left: calc(-50% + 18px) !important;
        right: calc(50% + 18px) !important;
    }
}