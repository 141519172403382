@import url('https://fonts.googleapis.com/css2?family=Acme&family=Mulish:wght@600&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');
$theme-color: #A45BB4;
:root {
    /* Font family */
    --default-font-family-acme: 'Acme';
    --font-family-mulish: 'Mulish';
    --font-family-inter: 'Inter', sans-serif;
    --font-family-oswald: 'Oswald', sans-serif;
    /* Colors */
    --background-color: #F6EFF8;
    --white-color: #FFFFFF;
    --theme-color: #A45BB4;
    --theme-color-light: #EDDEF0;
    --black-color: #000000;
    --black-color-dark: #363936;
    --black-color-light: #5E615E;
    --gray-color-dark: #868886;
    --grey-light-color: #AFB0AF;
    --gray-color: #7e7e7e;
    --gray-color-light: #EFEFEF;
    --darkest-grey-color: #ebebeb77;
    --lightest-grey-color: #d7d7d7;
    --danger-color: #f92c2c;
    --danger-color-rgb: 255, 0, 0;
    --danger-color-light: #f77;
    --danger-color-dark: #951a1a;
    --danger-color-contrast: #fff;
    --success-color: #6cc070;
    --success-color-rgb: 108, 192, 112;
    --success-color-light: #94d896;
    --success-color-dark: #5fa963;
    --success-color-contrast: #fff;
    --successLight-color: #a9dfab;
    --successLight-color-rgb: 169, 223, 171;
    --successLight-color-light: #b2e2b3;
    --successLight-color-dark: #95c496;
    --successLight-color-contrast: #000;
    --warning-color: #f80;
    --warning-color-rgb: 255, 136, 0;
    --warning-color-light: #f6fc9e;
    --warning-color-dark: #d70;
    --warning-color-contrast: #fff;
    --primary-color: #1492e6;
    --primary-color-rgb: 0, 153, 204;
    --primary-color-light: #2c9de9;
    --primary-color-dark: #1280ca;
    --primary-color-contrast: #fff;
    --light-pink-color: #e46bff;
    --light-blue-color: #5552c6;
    --darkest-blue-color: #6A54C1;
    --light-green-color: #1BA74A;
    --greenish-black-color: #363636;
    --light-white-color: #d9d9d9;
    --white-medium-color: #DBBDE1;
    --bg-light-grey-color: #e2e2e2;
    --header-height: 110px;
    --footer-height: 140px;
}