@import "../assets/css/variable.scss";

.white-bg {
  background-color: #f4edf6 !important;
}

.home-bg {
  background-color: var(--white-color) !important;
}

.home-arrow {
  top: 5%;
  left: 29%;
  position: absolute;
  display: flex;
}

.home-rel {
  position: relative;
}

.home-title {
  span {
    color: var(--theme-color);
  }
}

.home-btn {
  // max-width:740px;
  padding-left: 95px;
}

.babysitter-number {
  width: fit-content;
  text-align: center;
  margin: 8px 14px -2px 30px;

  h4 {
    line-height: 39px !important;
    font-size: 46px;
    color: var(--theme-color);
    font-weight: 400;
  }

  p {
    line-height: 14px !important;
    font-size: 14px;
    color: #e190bd;
  }
}

.number-text {
  font-family: var(--default-font-family-acme);
}

.babysitter-across-usa {
  font-weight: 700;
  font-family: var(--font-family-inter);
}

.sec-two-img {
  max-width: 100%;
  margin-top: 10px;
  // max-height:650px;
}

.home-title h1 {
  font-family: var(--default-font-family-acme);
  font-style: normal;
  font-weight: 400;
  font-size: 46px;
  line-height: 58.24px;
  text-transform: uppercase;
  color: var(--black-color);
}

.ads {
  p {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    font-family: var(--font-family-inter);
  }
}

// .local {
//   h3 {
//     color: var(--theme-color);
//     font-size: 46px;
//     line-height: 58.24px;
//     font-weight: 400;
//     font-family: var(--default-font-family-acme);
//   }
//   p {
//     font-size: 20px;
//     line-height: 24.5px;
//     font-weight: 400;
//     font-family: var(--font-family-inter);
//     color: var(--black-color);
//   }
// }
// .local-content {
//   h1 {
//     font-size: 28px;
//     line-height: 35.45px;
//     color: var(--theme-color);
//   }
//   p {
//     font-size: 16px;
//     line-height: 19.36px;
//     font-family: var(--font-family-inter);
//     color: var(--black-color);
//   }
// }

@media screen and (min-width: 1024px) and (max-width: 1146px) {
  .sec-two-img {
    max-width: 500px;
    max-height: 550px;
  }
}

.article-section {
  text-align: center;
  // padding: 50px 0;
}

// --------------mobile-----------------

// .sec-one-title-mobile {
//   max-width: 550px;
// }

// .title-pos-mobile {
//   position: absolute;
//   top: -4.688rem;
// }
.sec-two-img .girl-img {
  height: 100%;
  width: 100%;
}

@media screen and                  (min-width:766px) and (max-width: 912px) {
  .home-arrow {
    top: 1%;
    left: 35%;
  }

  .home-btn {
    // max-width:740px;
    padding-left: 30px;
  }
}
.article-link:hover{
  color: var(--theme-color) !important;
}
// .home-card {
//   width: 50% !important;
// }

.home-card {
  font-family: var(--default-font-family-acme);
  color: var(--theme-color);
  font-size: 28px;
}

// .home-card_layout {
//   border-radius: 10px;
// }

.other-card-description {
  font-size: 24px;
  margin-left: 65px;
  font-family: var(--font-family-inter);
  line-height: 29px;
  color: var(--black-color);
}

// .press_description_third{
//   margin-bottom: 13px;
// }

.little-description {
  margin-left: 67px;
  font-size: 19px;
  color: var(--theme-color);
  font-weight: 700 !important;
  font-size: 24px;
  line-height: 29px;
  font-family: var(--font-family-inter);
}

.image-border-radius {
  border-radius: 10px;
}

// .card_aligment {
//   padding: 35px 30px 0px;
// }

// .press_description {
//   font-family: var(--default-font-family-acme);
//   font-size: 18px;
//   line-height: 22px;
//   text-transform: capitalize;
//   color: var(--black-color);
//   margin-bottom: 23px;
// }

// .press_images {
//   width: 200px !important;
//   height: 42px !important;
// }

// .read_article {
//   color: var(--theme-color);
//   text-transform: uppercase;
//   font-weight: 700;
//   font-family: var(--font-family-inter);
//   font-size: 18px;
// }

// .press-text {
//   font-family: var(--default-font-family-acme);
//   color: var(--theme-color);
//   font-size: 46px;
// }

// .press_text_card {
//   width: 409px;
//   height: 298px;
// }

.find-babysitter-section-text {
  font-family: var(--default-font-family-acme);
  color: var(--theme-color);
  text-align: center;
  font-size: 46px;
  font-weight: 400;
}

.find-babysitter-section-description {
  text-align: center;
  font-size: 28px;
  line-height: 33.89px;
  font-family: var(--font-family-inter);
  margin-top: 20px;
  color: var(--black-color);
}

// .card_aligment {
//   height: 250px !important;
//   box-shadow: 0px 2px 10px 0px #00000040;
// }

@media (min-width: 1332px) and (max-width: 1511px) {
  // .mobile_set_section {
  //   bottom: 34px !important;
  //   position: absolute !important;
  // }

  // .home-card_height {
  //   width: 269px !important;
  // }
  .image-border-radius {
    width: 552px;
  }

  // .home-card_height {
  //   width: 269px !important;
  // }
  // .home-card_height {
  //   width: 270px !important;
  // }
}

// .set_home-card_padding {
//   padding-bottom: 0px !important;
// }

// .home-card_height {
//   height: 280px;
// }
// .set-text-margin-read {
//   margin-bottom: 60px;
// }

// .mobile_set_section {
//   bottom: 46px !important;
//   position: absolute;
// }
// .home-card_height {
//   height: 280px;
//   width: 305px !important;
// }

@media (min-width: 766px) and (max-width: 1025px) {
  .second-portion-text {
    flex-direction: column-reverse;
    display: flex;
  }

  // .side_line{
  //   font-size: 14px !important;
  // }
  .read-the-article-image {
    width: 250px;
    height: 100px;
  }

  // .read_article{
  //   font-size: 13px;
  // }
  // .card_aligment {
  //   padding: 11px 12px 0px;

  // }
  // .press_text_card {
  //   width: unset;
  // }
  .babysitter-number h4 {
    font-size: 28px;
  }

  .babysitter-number {
    margin: -7px 16px 0px 2px;
  }

  // .gap-card {
  //   gap: 5px;
  // }
}

// .gap-card{
//   gap: 20px;
// }

// .card_aligment_three{
//   padding: 28px 30px 0px !important;
// }
// .second-portion-text{
//   flex-direction: row;
// }
.as-seen-in {
  margin-bottom: -28px;
  color: var(--black-color) !important;
}

// @media screen and (min-width: 1375px) and (max-width: 1515px) {
//   .home-card_height {
//     width: 305px !important;
//   }
// }

// @media only screen and (min-width: 1023px) and (max-width: 1025px)  {
//   // .mobile_set_section{
//   //   position: absolute;
//   //   bottom: -39px;
//   // }
//   // .card_for_tablet{
//   //   width: 288px;
//   // }
//   .home-container-updated{
//     padding-bottom: 60px;
//   }

// }

// @media only screen and (min-width: 1300px) and (max-width: 1400px) {
//   .gap-card{
//     gap: 15px;
//   }

// }

// .mobile_set_section{
//   position: absolute;
//   bottom: -17px;

// }
// .tablet_middle_card{
//   width: 332px !important;
// }
// .image-border-radius {
//   width: 503px !important;
// }

// .circle-img-one{
//   width: 425px ;
//   height: 425px ;
// }
// .circle-img{
//   width: 425px ;
//   height: 425px ;
// }
// .how-see-layout{
//   margin-left: 100px;
//   margin-right: 100px;
// }

@media (max-width: 674px) and (min-width: 500px) {
  .image-border-radius {
    width: 500px;
  }

  .yahoo-image-phone {
    width: 150px !important;
  }

  .nbc-image-phone {
    width: 150px !important;
  }

  .number-text {
    font-size: 20px !important;
  }

  .babysitter-number {
    margin: 0px !important;
  }
}

@media (max-width: 1023px) and (min-width: 991px) {
  .image-border-radius {
    width: 395px !important;
  }

  // .image_tablet_text_image{
  //   width: 395px !important;
  // }
  // .medium_tablet_card{
  //   width: 287px;
  // }
  // .circle-img{
  //   width: 400px !important;
  //   height: 400px !important;
  // }
  // .circle-img-one{
  //   width: 400px !important;
  //   height: 400px !important;
  // }
}

@media (max-width: 1025px) and (min-width: 1149px) {
  .image-border-radius {
    width: 395px !important;
  }

  // .image_tablet_text_image{
  //   width: 395px !important;
  // }
  // .medium_tablet_card{
  //   width: 287px;
  // }
  // .circle-img{
  //   width: 400px !important;
  //   height: 400px !important;
  // }
  // .circle-img-one{
  //   width: 400px !important;
  //   height: 400px !important;
  // }
}

@media (max-width: 575px) and (min-width: 767px) {
  .image-border-radius {
    width: 395px !important;
  }

  // .medium_tablet_card{
  //   width: 287px;
  // }
  // .circle-img{
  //   width: 400px !important;
  //   height: 400px !important;
  // }
  // .circle-img-one{
  //   width: 400px !important;
  //   height: 400px !important;
  // }
}

// @media (max-width: 1900px) and (min-width: 1301px) {
//   .card-padding{
//     padding: 0px 88px !important;
//   }
// }

@media (max-width: 1196px) and (min-width: 1023px) {
  .image-border-radius {
    width: 490px !important;
  }
}

@media (max-width: 762px) and (min-width: 676px) {
  .babysitter-number {
    margin: 0px;
  }

  .babysitter-number h4 {
    font-size: 20px;
  }

  .image-border-radius {
    width: 500px;
  }
}

.see_how_its_work {
  font-size: 16px;
}

.grid-card {
  background-color: var(--white-color);
  border-radius: 10px;
  text-align: center;
  padding: 20px 25px;
  margin: 15px 0;
}

@media (min-width: 1513px) and (max-width: 2000px) {
  .grid-card {
    padding: 20px 10px;
}
}

// .btn-purple {
//   background-color: var(--theme-color);
//   color: white;
//   border: none;
//   padding: 10px 30px;
//   border-radius: 25px;
//   text-align: center;
//   display: inline-block;
//   margin-top: 30px;
//   text-decoration: none;
// }
.card-description {
  font-size: 16px;
  font-weight: 400;
  font-family: var(--font-family-inter);
  color: var(--black-color);
}

// .testimonial {
//   background-color: #f4eef8;
//   padding: 50px 0;
// }
// .testimonial-quote {
//   font-size: 50px;
//   color: var(--theme-color);
// }
// .testimonial-text {
//   font-size: 18px;
//   margin-bottom: 20px;
// }
// .testimonial-author {
//   font-weight: bold;
//   color: var(--theme-color);
// }
// .image-container img {
//   width: 100%;
//   height: auto;
//   border-radius: 5px;
// }
// .btn-custom {
//   background-color: var(--theme-color);
//   color: white;
//   border: none;
//   margin-top: 30px;
// }
// .btn-custom:hover {
//   background-color:var(--theme-color);
// }
// .btn-purple:hover {
//   background-color: #8e60a5;
// }

@media screen and (max-width: 766px) {
  .home-btn1 {
    width: 248px;
    height: 45px;
  }

  .home-btn2 {
    width: 173px;
    height: 45px;
  }
}

@media screen and (max-width: 400px) {
  .girl-img {
    max-width: -webkit-fill-available;
  }

  .read-the-article-image {
    max-width: -webkit-fill-available;
  }
}

.set-padding-card-col {
  padding-left: 57px !important;
}
.padding-image-card {
  padding-left: 9%;
}
.secondsection-header {
  padding: 0 12px;
  text-align: center;

  h2 {
    color: var(--theme-color);
    font-size: 46px;
    font-weight: 400;
    font-family: var(--default-font-family-acme);
    text-align: center;
  }

  p {
    font-size: 20px;
    font-weight: 400;
    font-family: var(--font-family-inter);
    color: var(--black-color);
  }
}

.coloradoparent-image-phone {
  width: 262px;
  height: 42px;
}

.yahoo-image-phone {
  width: 150px;
  height: 150px;
}

.nbc-image-phone {
  width: 71px;
  height: 70px;
}

.article-title {
  color: var(--theme-color);
  font-size: 46px;
  font-weight: 400;
  // margin-bottom: 40px;
  font-family: var(--default-font-family-acme);
}

// .article-card {
//   border: 1px solid #ddd;
//   border-radius: 10px;
//   padding: 20px 22px;
//   text-align: left;
//   transition: transform 0.3s ease;
//   box-shadow: 0px 4px 8px #00000040;
//   background-color: var(--white-color);
//   height: 100%;
//   width: 100%;
//   display: flex;
//   flex-direction: column;
//   justify-content: space-between;
// }

.article-link {
  color: var(--theme-color);
  text-decoration: none;
  font-size: 18px !important;
  font-weight: 700 !important;
  font-family: var(--font-family-inter) !important;
  text-decoration: underline;
}

.article-content {
  font-size: 18px;
  font-weight: 400;
  font-family: var(--default-font-family-acme);
  margin-bottom: 80px;
  color: var(--black-color);
  line-height: 22.79px;
}

.article-content-second{
  font-size: 18px;
  font-weight: 400;
  font-family: var(--default-font-family-acme);
  margin-bottom: 82px;
  color: var(--black-color);
  line-height: 22.79px;
}

.article-content-last {
  font-size: 18px;
  font-weight: 400;
  font-family: var(--default-font-family-acme);
  margin-bottom: 57px;
  color: var(--black-color);
  line-height: 22.79px;
}

.article-logo {
  width: 120px;
  margin-bottom: 15px;
}

/* Mobile view adjustment */
@media (max-width: 768px) and (min-width: 577px) {
  .article-title {
    font-size: 2rem;
  }

  .second-section {
    padding-left: 50px;
    padding-right: 50px;
  }

  .home-title {
    h1 {
      font-size: 28px;
      line-height: 34.24px;
    }
  }

  .secondsection-header {
    padding: 0 12px;
    text-align: center;

    h2 {
      font-size: 28px;
    }

    p {
      font-size: 16px;
    }
  }
}

// .custom-section {
//   background-color: #f4e9f8;
//   padding: 40px 0;
//   text-align: center;
// }

// .custom-section h1 {
//   font-size: 2rem;
//   font-weight: bold;
//   color: var(--theme-color);
// }

// .custom-section p {
//   font-size: 1.2rem;
//   margin-bottom: 30px;
// }

@media only screen and (max-width: 576px) {
  .babysitter-number h4 {
    line-height: 17px !important;
    font-size: 32px;
    padding-bottom: 7px;
  }
  .article-content{
    margin-bottom: 40px;
  }

  .article-content-second{
    margin-bottom: 45px;
  }
  .In-the-press-card-phone{
    padding: 0px 30px !important;
  }

  .article-content-last{
    margin-bottom: 25px;
  }

  .grid-card {
    padding: 20px 60px;
  }

  .home-arrow-img {
    width: 106px !important;
    height: 99px !important;
  }

  .article-section .card img{
    margin-bottom: 0px !important;
  }

  // .image_tablet_text_image{
  //   display: flex;
  //   justify-content: center;
  // }
  // .press_col_card{
  //   display: flex;
  //   justify-content: center;
  // }
  // .press-text{
  //   font-size: 28px !important;
  //   font-weight: 400;
  // }
  .article-title {
    font-size: 28px;
  }

  .second-section {
    padding: 0px !important;
  }

  .secondsection-header {
    h2 {
      font-size: 28px;
    }

    p {
      font-size: 14px;
    }
  }

  .card-description {
    font-size: 14px;
    line-height: 16.94px;
  }

  .article-content {
    font-size: 14px;
    line-height: 17.72px;
  }

  .article-content-second{
    font-size: 14px;
    line-height: 17.72px;
  }

  .article-content-last{
    font-size: 14px;
    line-height: 17.72px;
  }

  // .cardbox{
  //   margin: auto !important;
  // }
  .find-babysitter-section-text {
    font-size: 28px !important;
  }

  // .home-card_height {
  //   width: 323px !important;
  //   height: 253px !important;
  // }
  .little-description {
    font-size: 22px;
  }

  .coloradoparent-image-phone {
    width: 149px !important;
    height: 24px !important;
  }

  .yahoo-image-phone {
    width: 85px !important;
    height: 85px !important;
  }

  .nbc-image-phone {
    width: 40px !important;
    height: 40px !important;
  }

  // .buttonhome {
  //   position: relative !important;
  // }

  .last-btn {
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: center;
    align-items: center;
    margin-top: 40px;

    button {
      &:first-child {
        order: 2;
      }

      &:last-child {
        order: 1;
      }
    }
  }

  .home-btn {
    .set-button-in-mobile {
      display: flex;
      flex-direction: column;
      gap: 10px;

      button {
        &:first-child {
          order: 2;
          width: 173px;
          font-weight: 600;
          font-size: 16px;
        }

        &:last-child {
          order: 1;
          width: 248px;
          font-weight: 600;
          font-size: 16px;
        }
      }
    }
  }

  // .card_set_for_mobile {
  //   width: 324px !important;
  //   height: 221px !important;
  // }

  // .sec-two-img .girl-img {
  //   height: 263px;
  //   width: 395px;
  // }
  // .set-button-in-mobile {
  //   display: flex;
  //   flex-direction: column-reverse;
  //   width: 80%;
  // }
  // .card_aligment {
  //   padding: 35px 20px 0px !important;
  // }
  // .local h3 {
  //   font-size: 28px;
  //   line-height: 35.45px;
  // }
  .home-card {
    font-size: 22px;
    line-height: 27.85px;
  }

  // .local-content p {
  //   font-size: 14px;
  //   line-height: 16.94px;
  // }
  // .local p {
  //   font-size: 14px;
  //   line-height: 16.94px;
  //   color: var(--black-color);
  // }
  .home-title h1 {
    font-family: var(--default-font-family-acme);
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 27.85px;
    text-transform: uppercase;
    color: var(--black-color);
  }

  .babysitter-number p {
    font-size: 10px;
  }

  .babysitter-number {
    margin: -9px 3px;
  }

  .home-arrow{
    top: 1%;
    left: 29% !important;
    }
  

  .other-card-description {
    font-size: 22px;
    margin-left: 10px;
    margin-right: 14px;
    font-family: var(--font-family-inter);
    line-height: 26px;
  }

  .little-description {
    margin-left: 10px !important;
  }

  .image-border-radius {
    width: 323px !important;
    height: 168px !important;
  }

  // .press_description {
  //   font-size: 14px !important;
  //   line-height: 17.72px !important;
  //   margin-bottom: 77px !important;
  //   font-weight: 400;
  // }
  .find-babysitter-section-description {
    text-align: center;
    font-size: 14px !important;
    line-height: 16.94px !important;
    font-family: var(--font-family-inter);
    font-weight: 500 !important;
    margin-top: 20px;
  }

  // .press_description_third {
  //   margin-bottom: 30px !important;
  // }
  .article-section {
    text-align: center;
    // padding: 10px 0;
  }

  .article-title {
    margin-bottom: 10px !important;
  }
}

@media screen and (max-width: 393px) {
  .other-card-description,
  .little-description {
    font-size: 22px;
  }
}

@media screen and (max-width: 1100px) and (min-width: 768px) {
  .home-title {
    h1 {
      font-size: 28px;
      line-height: 34.24px;
    }
  }

  .home-arrow {
    img {
      width: 110px;
      height: 95px;
    }
  }

  .image-border-radius {
    width: 356px;
    height: 220px;
  }

  .other-card-description {
    font-size: 18px;
  }
}

@media screen and (min-width: 1299px) and (max-width: 2000px) {
  .parent-description-padding {
    padding-right: 84px !important;
  }

  .parent-image-padding {
    padding-left: 60px !important;
  }

  .second-section {
    padding-left: 104px;
    padding-right: 104px;
  }

  .sec-one-title {
    padding-left: 104px !important;
    padding-right: 40px !important;
  }
}

@media screen and (min-width: 767px) and (max-width: 1299px) {
  .sec-one-title {
    padding-left: 50px !important;
    padding-right: 30px !important;
  }
  .second-section {
    padding-left: 50px;
    padding-right: 50px;
  }
}

.article-section {
  .card {
    border: none;
    text-align: start;
    background-color: var(--white-color);

    box-shadow: 0px 2px 10px 0px #00000040;
    border-radius: 10px;
  }

  .card img {
    width: 200px;
    height: 67px;
    object-fit: contain;
    margin-bottom: 17px;
  }

  .card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .card-title {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .card a {
    font-weight: bold;
    text-transform: uppercase;
  }
}

@media (max-width: 400px) and (min-width: 350px) {
  .babysitter-number h4 {
    font-size: 32px !important;
  }
}

@media (max-width: 351px) and (min-width: 320px) {
  .babysitter-number h4 {
    font-size: 14px !important;
  }
  .coloradoparent-image-phone {
    width: 100px !important;
    height: 100px !important;
  }
  .yahoo-image-phone {
    width: 50px !important;
    height: 50px !important;
  }
  .nbc-image-phone {
    width: 50px !important;
    height: 50px !important;
  }
}
