// @import '../assets/css/variable.scss';
// .section-first-title {
//     font-size: 2.625rem;
//     line-height: 53px;
//     letter-spacing: 5px;
//     color: var(--black-color-dark);
//     font-family: var(--default-font-family-acme);
//     max-width: 520px;
// }

// .description-style {
//     font-size: 1.25rem;
//     line-height: 30px;
//     letter-Spacing: 1px;
//     color: var(--black-color);
//     font-family: var(--font-family-mulish);
//     max-width: 538px;
//     text-align: justify;
// }

// .circle-img-one {
//     height: calc(100% - 10px);
//     width: calc(100% - 10px);
// }

// .circle-img {
//     height: calc(100% - 10px);
//     width: calc(100% - 10px);
// }

// .parent-pos {
//     position: relative;
// }

// .circle-two {
//     position: absolute;
//     left: -118px;
//     bottom: -127px;
// }

// .circle-one {
//     position: absolute;
//     bottom: -132px;
//     right: -130px;
// }

// .box-two-top {
//     margin-top: 123px !important;
// }

// .mobile-circle {
//     height: 168px;
//     width: 168px;
// }

// .layout-box {
//     background-color: var(--white-color);
//     padding: 65px;
//     border-radius: 11px;
// }

// .parent-layout {
//     margin-Left: 195px;
//     margin-right: 195px;
// }

// @media only screen and (max-width: 1023px) {
//     .parent-layout {
//         margin-Left: 70px;
//         margin-right: 70px;
//     }
// }

// @media only screen and (max-width: 572px) {
//     .parent-layout {
//         margin-Left: 30px;
//         margin-right: 30px;
//     }
// }

// @media only screen and (max-width: 768px) {
//     .layout-box {
//         background-color: var(--white-color);
//         padding: 21px;
//         border-radius: 11px;
//     }
//     .box-two-top {
//         margin-top: 54px !important;
//     }
//     .section-first-title {
//         font-size: 0.938rem;
//         line-height: 21px;
//         letter-spacing: 2px;
//         color: var(--black-color-dark);
//         font-family: var(--default-font-family-acme);
//     }
//     .description-style {
//         font-size: 0.75rem;
//         line-height: 20px;
//         letter-Spacing: 1px;
//         color: var(--black-color);
//         font-family: var(--font-family-mulish);
//         max-width: 60%;
//         text-align: justify;
//     }
//     .circle-one {
//         position: absolute;
//         bottom: -36px;
//         right: -26px;
//     }
//     .circle-two {
//         position: absolute;
//         left: -21px;
//         bottom: -60px;
//     }
// }

// @media only screen and (max-width: 374px) {
//     .layout-box {
//         background-color: var(--white-color);
//         padding: 15px 2px 2px;
//         border-radius: 10px;
//     }
// }

// @media only screen and (min-width: 376px) and (max-width: 416px) {
//     .mobile-circle {
//         height: 143px;
//         width: 143px;
//     }
// }

// @media only screen and (max-width: 375px) {
//     .mobile-circle {
//         height: 128px;
//         width: 128px;
//     }
// }

// // new code from here

// .circle-one {
//     position: absolute;
//     bottom: 25px;
//     right: -130px;
// }

// New code beginnes
@import "../assets/css/variable.scss";
.section-first-title {
  font-size: 46px;
  line-height: 53px;
  letter-spacing: 0px;
  //   color: var(--theme-color);
  color: var(--theme-color) !important;
  font-family: var(--default-font-family-acme);
  max-width: 520px;
}

.description-style {
  font-size: 24px !important;
  line-height: 29.05px;
  letter-spacing: 0px !important;
  //   color: var(--black-color);
  color: #000000 !important;
  font-family: var(--font-family-inter);
  font-weight: 400 !important;
  line-height: 29.05px !important;
  margin-top: 35px !important;
}

.circle-img-one {
  height: 465.2px;
  width: 475px;
}

.circle-img {
  height: 475px;
  width: 475px;
}

.parent-pos {
  position: relative;
}
@media screen and (max-width: 1900px) and (min-width: 1500px) {
  .circle-two {
    position: absolute;
    // left: -143px;
    // top: -134px;
    position: absolute;
    left: -101px !important;
    top: -166px !important;
  
  }
}


.circle-two {
  position: absolute;
  left: -143px;
  top: -134px;
  

}


.circle-one {
  position: absolute;
  top: -188px;
  right: -103px;
}

.box-two-top {
  margin-top: 123px !important;
}

.mobile-circle {
  height: 168px;
  width: 168px;
}
.page-title {
  font-size: 2.5rem;
  letter-spacing: 0px !important;
}
.layout-box {
  background-color: var(--white-color);
  padding: 65px;
  border-radius: 11px;
  box-shadow: 0px 2px 10px #00000040;
}

.parent-layout {
  margin-left: 195px;
  margin-right: 195px;
}

.how-see-layout {
  margin-left: 119px;
  margin-right: 119px;
  margin-top: 40px !important;
}

// @media screen and (min-width: 992px) and (max-width: 1200px) {
//   .how-see-layout {
//     margin-left: 105px;
//     margin-right: 105px;
//   }
// }

@media screen and (min-width: 1200px) {
  .our-layout{
    padding: 80px 150px !important;
  }
}

@media only screen and (max-width: 1023px) {
  .parent-layout {
    margin-left: 70px;
    margin-right: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .parent-layout {
    margin-left: 30px;
    margin-right: 30px;
  }
  // .circle-two {
    // position: absolute !important;
    // left: 50% !important;
    // transform: translate(-50%, 40%) !important;
  // }

  .first_card{
    width: auto !important;
  }
  .section-first-title{
    font-size: 20px;
  }

  .about_tittle{
    font-size: 28px !important;
    margin: 40px 0 20px !important
  }

  .about-us-parent-layout{
    margin-top: 45px !important;
  }

  .circle-one {
    position: absolute !important;
    left: 50% !important;
    transform: translate(-50%, -170%) !important;
    bottom: 133px !important;
    right: auto !important;
  }
  .description-style {
    // max-width: 100% !important;
    text-align: start;
  }
  .section-first-title {
    text-align: center;
  }
  .aspen {
    margin-bottom: 300px !important;
  }
  .about-us-parent-layout {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }
  .description-style {
    font-size: 14px !important;
    line-height: 16px !important;
  }
  .img_size_mobile {
    height: 240px !important;
    width: 245px !important;
  }
  .set_image_gap_mobile {
    margin-bottom: 310px;
  }
  .set_margin_top_image_mobile {
    margin-top: 300px !important;
  }
}
@media only screen and (max-width: 768px) {
  .layout-box {
    background-color: var(--white-color);
    padding: 21px;
    border-radius: 11px;
  }
  .box-two-top {
    margin-top: 54px !important;
  }
  .section-first-title {
    font-size: 22px !important;
    line-height: 25px;
    letter-spacing: 1px;
    color: var(--theme-color);
    // font-family: var(--default-font-family-acme);
  }
  .description-style {
    font-size: 0.75rem;
    line-height: 20px;
    letter-spacing: 1px;
    color: var(--black-color);
    // font-family: var(--font-family-mulish);
    // max-width: 96% !important;
    text-align: justify;
  }
  // .circle-one {
  //   position: absolute;
  //   bottom: -36px;
  //   left: 43px;
  // }
  // .circle-two {
  //   position: absolute;
  //   left: -21px;
  //   bottom: 130px;
  // }
  .aspen {
    margin-bottom: 85px;
  }
}

@media only screen and (max-width: 374px) {
  .layout-box {
    background-color: var(--white-color);
    padding: 15px 2px 2px;
    border-radius: 10px;
  }
}

@media only screen and (min-width: 376px) and (max-width: 416px) {
  .mobile-circle {
    height: 143px;
    width: 143px;
  }
}

@media only screen and (max-width: 375px) {
  .mobile-circle {
    height: 128px;
    width: 128px;
  }
}

.about-us-parent-layout {
  margin-left: 100px;
  margin-right: 100px;
  margin-top: 45px;
}

// .aspen{
//     padding:30px 65px !important;
// }
@media (min-width: 766px) and (max-width: 1025px) {
  .circle-img-one {
    height: 307px !important;
    width: 307px !important;
  }
  .circle-img {
    height: 307px !important;
    width: 307px !important;
  }
  
}

@media (min-width: 1025px) and (max-width: 1511px) {
  // .circle-two {
  //   position: absolute;
  //   left: -118px;
  //   bottom: 170px;
  // }
}

// @media (min-width: 1513px) and (max-width: 1920px) {
//     .circle-two {
//         position: absolute;
//         left: -118px;
//         bottom: 177px;
//     }
// }

.about_tittle {
  color: #000000 !important;
  font-weight: 400 !important;
  line-break: 58.24px !important;
}

@media screen and (min-width:767px){
  .about_tittle{
    font-size: 46px !important;
  }


.ourstory_card{
  padding:77px 65px 40px !important
}

.ourstory_card2{
    // padding:77px 65px 40px !important;
    margin-top: 9rem !important;
    margin-bottom: 8rem;
}
}

@media (max-width: 1150px) and (min-width: 980px) {
  .circle-img{
    width: 400px;
    height: 400px;
  }
  .circle-img-one{
    width: 400px ;
    height: 400px;
  }
  
}

@media (max-width: 767px) and (min-width: 573px) {
  // .circle-one{
  //   position: absolute;
  //   bottom: 101px;
  //   left: 231px;
  //   right: 64px;
  // }
//   .circle-two {
//     position: absolute;
//     left: 260px;
//     bottom: 505px;
// }
.margin-bottom-about-us{
  margin-top: 40px !important;
}
}


@media screen and (min-width:320px) and (max-width: 650px){
  // .first_card{
  //   width: 75% !important;
  // }
  // .circle-one {
  // left: 300px !important;
  // bottom: 112px !important;
  // img{
  //   width: 200px !important;
  //   height: 200px !important;
  // }
  // }
  .description-style{
    line-height: 16.94px !important;
    font-size: 14px !important;
  }

  // .circle-two {
  //   left: 300px !important;
  //   bottom: 446px !important;
  //   img{
  //     width: 200px !important;
  //     height: 200px !important;
  //   }
  //   }

}

@media screen and (min-width:649px) and (max-width: 766px){
  // .first_card{
  //   width: 75% !important;
  // }
  // .circle-one {
  //   left: 371px !important;
  //   bottom: 13px !important;
  // img{
  //   width: 200px !important;
  //   height: 200px !important;
  // }
  // }
  .description-style{
    line-height: 20px !important;
  }

  // .circle-two {
  //   left: 357px !important;
  //       bottom: 237px !important;
  //   img{
  //     width: 200px !important;
  //     height: 200px !important;
  //   }
  //   }

}

@media screen and (min-width:767px) and (max-width:770px){
  // .description-style{
  //   line-height: 16px !important;
  // }
  .section-first-title{
    font-size: 21px !important;
  }
}

@media only screen and (min-width: 800px) and (max-width: 1300px) {
  // .circle-two{
  //   position: absolute;
  //   bottom: -9px;
  //   right: -130px;
  // }
  .description-style{
    font-size: 14px;
    line-height: 18px ;
  }
  .section-first-title{
    font-size: 30px;
  }
  // .circle-one{
  //   position: absolute;
  //   top: -191px;
  //   left: -8px;
  // }
  .section-first-title{
    line-height: 30px;
  }
  
}

// @media only screen and (min-width: 700px) and (max-width: 800px) {
//   .circle-two {
//     position: absolute;
//     left: -137px;
//     top: -150px;
//   }
// }

@media only screen and (min-width: 600px) and (max-width: 801px) {
//   .circle-two {
//     position: absolute;
//     left: -81px !important;
//     top: -95px !important;

// }
.layout-box{
  padding: 20px;
}
}

@media screen and (max-width: 767px) and (min-width: 320px) {
  .circle-two {
    position: absolute !important;
    left: 50% !important;
    transform: translate(-50%, -60%) !important;
  }
}


@media screen and (min-width:767px) and (max-width: 1024px){
  .section-first-title{
    font-size: 28px;
  }
  .circle-one{
    top: -139px;
    right: -144px;
  }

  .circle-img-one{
    width: 375px ;
    height: 375px;
  }
  .circle-img{
    width: 375px ;
    height: 375px;
  }
  .ourstory_card{
    padding: 77px 60px 35px 0px !important;
     }
}

@media screen and (min-width:765px) and (max-width:769px){
  .circle-two{
    left: -100px;
  }
  .ourstory_card{
 padding: 77px 60px 35px 0px !important;
  }
}

.setbreakbetween{
  margin-top: 21px !important;
}