@import './variable.scss';
.babysitterList {
    .card {
        border: none !important;
    }
    .card-header {
        letter-spacing: 0.1rem;
        text-transform: uppercase;
        color: var(--white-color);
        background-color: var(--theme-color);
        font-family: var(--default-font-family-acme);
        &:first-child {
            border-radius: 0.625rem 0.625rem 0 0 !important;
        }
        p {
            font-size: 1.17rem;
            margin-bottom: 0;
            margin-top: auto;
        }
    }
    .card-body {
        background-color: var(--theme-color-light);
        border-radius: 0 0 0.625rem 0.625rem !important;
        .sitter-img {
            width: 94px !important;
            height: 90px !important;
            border-radius: 50% !important;
        }
        .card-title {
            text-transform: uppercase;
            letter-spacing: 0.1rem;
            font-family: var(--default-font-family-acme);
        }
        .card-text {
            font-size: 1rem;
            font-family: var(--font-family-mulish);
            line-height: 1.3rem;
        }
        .card-btn {
            color: var(--black-color);
            font-family: var(--font-family-mulish);
            padding: 12px 39px;
            background-color: var(--white-color);
            border: none;
            border-radius: 0.625rem;
            font-weight: bold;
        }
        .card-btn:hover {
            color: var(--theme-color);
        }
        .card-btn:active {
            box-shadow: none;
            color: var(--white-color);
            background-color: var(--theme-color);
        }
    }
}

@media screen and (min-width:320px) and (max-width:571px) {
    .babysitterList {
        .card-header p {
            font-size: 0.9rem !important;
        }
        .card-text {
            text-align: justify;
            font-size: 0.9rem !important;
        }
        .card-body {
            .card-btn {
                padding: 10px 20px;
                font-size: 0.9rem !important;
            }
        }
    }
}

.interested-subtitle {
    font-family: var(--default-font-family-acme) !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 1.875rem !important;
    line-height: 37px !important;
    letter-spacing: 0.1em !important;
    // text-transform: lowercase !important;
}

.not-interested-list {
    max-width: 437.66px;
    max-height: 317.18px;
}

.not-interested-img {
    height: 100%;
    width: 100%;
}

@media screen and (max-width: 768px) {
    .interested-subtitle {
        font-family: var(--default-font-family-acme) !important;
        font-style: normal !important;
        font-weight: 400 !important;
        font-size: 0.688rem !important;
        line-height: 13px !important;
        letter-spacing: 0.1em !important;
    }
}