// // ---------------------how it work
// @import '../../assets/css/variable.scss';
// //  .form-title{
// //   font-size: 2.75rem !important;
// //   font-family: var(--default-font-family-acme);
// //   letter-spacing: 3px;
// //   color:var(--black-color-dark);
// //   text-transform: uppercase;
// // }
// // .page-discription{
// //   font-weight: 600;
// //   font-size: 20px;
// //   line-height: 30px;
// //   color: var(--gray-color-dark);
// //   font-family: var(--font-family-mulish);
// //   }
// .list-num-text {
//     font-size: 3rem;
//     font-family: var(--default-font-family-acme);
//     text-align: center;
//     color: var(--white-color)
// }

// .list-title {
//     font-size: 2.25rem;
//     font-family: var(--default-font-family-acme);
//     color: var(--greenish-black-color);
//     letter-spacing: 2px;
//     line-height: 46px;
//     text-transform: uppercase;
// }

// .list-description {
//     font-size: 1.25rem;
//     font-family: var(--font-family-mulish);
//     color: var(--gray-color-dark);
//     line-height: 30px;
//     text-align: justify;
// }

// .job-board {
//     background-color: var(--light-white-color);
//     min-height: 393px;
//     font-size: 3rem;
//     font-family: var(--default-font-family-acme);
//     line-height: 61px;
//     letter-spacing: 2px;
//     text-transform: uppercase;
// }

// .list-circle {
//     height: 70px;
//     width: 70px;
//     background-color: var(--theme-color);
//     border-radius: 70px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
// }

// @media screen and (max-width:767px) {
//     .list-circle {
//         height: 35px;
//         width: 35px;
//     }
//     .list-num-text {
//         font-size: 1.563rem;
//     }
//     .list-title {
//         font-size: 1.438rem;
//         font-family: var(--default-font-family-acme);
//         color: var(--greenish-black-color);
//         letter-spacing: 2px;
//         line-height: 29px;
//         text-transform: uppercase;
//     }
//     .list-description {
//         font-size: 0.875rem;
//         font-family: var(--font-family-mulish);
//         color: var(--gray-color-dark);
//         line-height: 20px;
//         text-align: justify;
//     }
//     .job-board {
//         font-size: 1.5rem;
//     }
// }

// new code start from here

// ---------------------how it work
@import '../../assets/css/variable.scss';

//  .form-title{
//   font-size: 2.75rem !important;
//   font-family: var(--default-font-family-acme);
//   letter-spacing: 3px;
//   color:var(--black-color-dark);
//   text-transform: uppercase;
// }
// .page-discription{
//   font-weight: 600;
//   font-size: 20px;
//   line-height: 30px;
//   color: var(--gray-color-dark);
//   font-family: var(--font-family-mulish);
//   }
.list-num-text {
    font-size: 3rem;
    font-family: var(--default-font-family-acme);
    text-align: center;
    color: var(--white-color)
}

.list-title {
    font-size: 2.25rem;
    font-family: var(--default-font-family-acme);
    color: var(--greenish-black-color);
    letter-spacing: 2px;
    line-height: 46px;
    text-transform: uppercase;
}

.list-description {
    font-size: 1.25rem;
    font-family: var(--font-family-mulish);
    color: var(--gray-color-dark);
    line-height: 30px;
    text-align: justify;
}

.job-board {
    background-color: var(--light-white-color);
    min-height: 393px;
    font-size: 3rem;
    font-family: var(--default-font-family-acme);
    line-height: 61px;
    letter-spacing: 2px;
    text-transform: uppercase;
}

.list-circle {
    height: 70px;
    width: 70px;
    background-color: var(--theme-color);
    border-radius: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.questions-title {
    font-size: 46px;
    font-family: var(--default-font-family-acme);
    color: var(--theme-color);
    letter-spacing: 2px;
    line-height: 46px;

}



@media screen and (max-width: 500px) {
    .questions-title{
        font-size: 22px;
        font-family: var(--default-font-family-acme);
        color: var(--theme-color);
        letter-spacing: 0.5px;
        line-height: 27px;
 }
 .question {
    h4 {
        font-size: 14px !important;
        line-height: 16px !important;
        font-weight: 700 !important;
        font-family: var(--font-family-inter);
        padding: 15px 0px 20px 0px !important;
        color: var(--theme-color);
    }
    p {
        font-size: 14px !important;
        line-height: 16px !important;
        font-weight: 500 !important;
        font-family: var(--font-family-inter);
        padding: 0px !important;
        color: var(--black-color) !important;
    }
    
}
}

.question {
    h4 {
        font-size: 32px;
        line-height: 38.73px;
        font-weight: 700;
        font-family: var(--font-family-inter);
        padding: 30px 0px 20px 0px;
        color: var(--theme-color);
       
    }

    p {
        font-size: 24px;
        line-height: 29.05px;
        font-weight: 400;
        font-family: var(--font-family-inter);
        padding: 0px !important;
        margin-bottom: 18px !important;
        color: var(--black-color) !important;
    }
}

@media screen and (max-width:767px) {
    .list-circle {
        height: 35px;
        width: 35px;
    }

    .list-num-text {
        font-size: 1.563rem;
    }

    .list-title {
        font-size: 1.438rem;
        font-family: var(--default-font-family-acme);
        color: var(--greenish-black-color);
        letter-spacing: 2px;
        line-height: 29px;
        text-transform: uppercase;
    }

    .list-description {
        font-size: 0.875rem;
        font-family: var(--font-family-mulish);
        color: var(--gray-color-dark);
        line-height: 20px;
        text-align: justify;
    }

    .job-board {
        font-size: 1.5rem;
    }
}

.workVideo{
    box-shadow: 0px !important;
}

@media screen and (max-width: 500px) {
    .how-see-layout{
        margin-left: 37px !important;
        margin-right: 37px !important;
    }
}

@media (min-width: 766px) and (max-width: 1025px) {

    .workVideo{
width: 763px;
height: 432px;
    }
    .how-see-layout{
        margin-left: 4rem;
        margin-right: 4rem;
    }

}


@media screen and (min-width:768px){
    .question h4{
        margin-bottom: 19px !important;
    }
    .question{
        margin-top: 30px !important;
    }
}