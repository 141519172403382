@import './variable.scss';
@import url('https://fonts.googleapis.com/css2?family=Acme&family=Mulish:wght@600&display=swap');
.layout-background-color {
    background-color: var(--background-color);
}

*,
*:before,
*:after {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

// -------------------- font -----------------------------
h1 {
    font-size: 1.5rem;
    font-weight: 500;
    word-break: break-word;
}

h2 {
    font-size: 1.25rem;
    font-weight: 500;
    word-break: break-word;
}

h3 {
    font-size: 1.125rem;
    font-weight: 500;
    word-break: break-word;
}

h4 {
    font-size: 1rem;
    font-weight: 500;
    word-break: break-word;
}

h5 {
    font-size: 0.9rem;
    font-weight: 500;
    word-break: break-word;
}

h6 {
    font-size: 0.875rem;
    font-weight: 500;
    word-break: break-word;
}

p {
    font-size: 0.75rem;
    word-break: break-word;
}
.font-10{
    font-size: 0.625rem !important;
}
.font-12 {
    font-size: 0.75rem !important;
}

.font-14 {
    font-size: 0.875rem !important;
}

.font-16 {
    font-size: 1rem !important;
}

.font-18 {
    font-size: 1.125rem !important;
}

.font-20 {
    font-size: 1.25rem !important;
}

.font-22 {
    font-size: 1.375rem !important;
}

.font-24 {
    font-size: 1.5rem !important;
}

.font-32 {
    font-size: 2rem !important;
}

.font-40 {
    font-size: 2.5rem !important;
}

.font-60 {
    font-size: 3.75rem !important;
}

.font-100 {
    font-size: 6.25rem !important;
}

.fw-100 {
    font-weight: 100;
}

.fw-200 {
    font-weight: 200;
}

.fw-300 {
    font-weight: 300;
}

.fw-400 {
    font-weight: 400;
}

.fw-500 {
    font-weight: 500;
}

.fw-600 {
    font-weight: 600;
}

.fw-700 {
    font-weight: 700;
}

.fw-800 {
    font-weight: 800;
}

.fw-900 {
    font-weight: 900;
}

.font-style {
    font-family: var(--font-family-mulish);
}

// --------------------font color------------------------------
.font-white {
    color: var(--white-color) !important;
}

.font-black {
    color: var(--black-color) !important;
}

.font-black-dark {
    color: var(--black-color-dark) !important;
}

.font-theme {
    color: var(--theme-color) !important;
}

.white-bg {
    background-color: var(--white-color) !important;
}

.theme-light-bg {
    background-color: var(--background-color) !important;
}

.lh-1 {
    line-height: 1 !important;
}

.lh-1_2 {
    line-height: 1.2 !important;
}

.lh-1_5 {
    line-height: 1.5 !important;
}

.lh-1_7 {
    line-height: 1.7 !important;
}

.padding-top-56 {
    padding-top: 56px;
}

.padding-top-58 {
    padding-top: 58px;
}

.margin-top-18 {
    margin-top: 18px;
}

.padding-top-71 {
    padding-top: 71px;
}

.about-page-arrow {
    height: 162px;
    width: 196px !important;
    margin-top: -5% !important;
}

.header-edit-username {
    position: absolute;
    top: 26px;
    right: 12.5%;
    margin-right: 7px;
}

// --------------------Header CSS------------------------------
.web-logo{
    cursor: pointer;
}

.App-header {
    background-color: var(--background-color);
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 1000;
}

.App-header-home {
    background-color: var(--white-color);
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 1000;
}

.navbar button.navbar-toggler {
    outline: 0 !important;
    border: none !important;
}

.navbar button.navbar-toggler {
    background: transparent !important;
    &:focus {
        box-shadow: none !important;
    }
}

.navbar-toggler-icon {
    display: inline-block !important;
    width: 1.5em !important;
    height: 1.5em !important;
    opacity: 60% !important;
}

.navbar-expand-lg .btn-login {
    background: var(--white-color);
    border: 1px solid var(--theme-color);
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    font-family: var(--font-family-mulish);
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--theme-color);
    &:hover,
    &:focus {
        background: var(--theme-color) !important;
        border: 1px solid var(--theme-color) !important;
        color: var(--white-color) !important;
    }
}

.navbar-expand-lg .btn-register {
    // background: var(--theme-color) !important;
    background-color: var(--theme-color) !important;
    border: 1px solid var(--theme-color) !important;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    font-family: var(--font-family-mulish) !important;
    font-style: normal;
    // width: 110px;
    height: 45px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: var(--white-color) !important;
    &:hover,
    &:focus {
        background: transparent !important;
        border: 1px solid var(--theme-color) !important;
        color: var(--theme-color) !important;
    }
}

.navbar-expand-lg .navbar-nav .nav-link {
    font-family: var(--font-family-mulish);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin: 0px 16px !important;
    color: var(--gray-color-dark);
    &:hover,
    &:focus,
    &:active {
        font-weight: 600;
        color: var(--theme-color) !important;
    }
}

.navbar-collapse ul {
    margin: 5px auto;
}

.navbar-collapse ul li a {
    display: flex;
    justify-content: start !important;
    padding: 15px 0px;
    margin: 0 !important;
    &:hover,
    &:focus {
        font-weight: 600;
        color: var(--theme-color) !important;
    }
}

.dropdown ul li a.dropdown-item {
    padding: 8px 10px !important;
    cursor: pointer;
}

.dropdown ul li a.dropdown-item:hover {
    font-weight: 400 !important;
    color: var(--theme-color) !important;
}

.dropdown ul li a.dropdown-item:focus,
.dropdown ul li a.dropdown-item:active {
    font-weight: 500 !important;
    color: var(--white-color) !important;
    background-color: var(--theme-color) !important;
}

.App-header .dropdown .dropdown-menu,
.App-header-home .dropdown .dropdown-menu {
    transform: translate(-80px, 4px) !important;
}

.dropdown-toggle::after {
    content: '\203A' !important;
    font: 20px "Consolas", monospace !important;
    font-weight: 700 !important;
    color: var(--white-color) !important;
    -webkit-transform: rotate(90deg) !important;
    -moz-transform: rotate(90deg) !important;
    -ms-transform: rotate(90deg) !important;
    transform: rotate(90deg) !important;
    vertical-align: middle !important;
    margin-left: 4px !important;
    padding-bottom: 5px !important;
    border: none !important;
}

.navbar-expand-lg .btn-register:hover.dropdown-toggle::after,
.navbar-expand-lg .btn-register:focus.dropdown-toggle::after,
.navbar-expand-lg .btn-register:active.dropdown-toggle::after {
    color: var(--theme-color) !important;
}

.App-header {
    .dropdown-menu-arrow {
        top: -25px;
        left: 50%;
        width: 0;
        height: 0;
        position: relative;
    }
    .dropdown-menu-arrow:before,
    .dropdown-menu-arrow:after {
        content: "";
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        border-width: 7px 8px;
        border-style: solid;
        border-color: transparent;
        z-index: 1001;
    }
    .dropdown-menu-arrow:after {
        bottom: -18px;
        right: -51px;
        border-bottom-color: var(--white-color);
    }
    .dropdown-menu-arrow:before {
        bottom: -16px;
        right: -51px;
        opacity: 15%;
        border-bottom-color: var(--black-color);
    }
}

.App-header-home {
    .dropdown-menu-arrow {
        top: -25px;
        left: 50%;
        width: 0;
        height: 0;
        position: relative;
    }
    .dropdown-menu-arrow:before,
    .dropdown-menu-arrow:after {
        content: "";
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        border-width: 7px 8px;
        border-style: solid;
        border-color: transparent;
        z-index: 1001;
        top: 3px;
        left: 51px;
    }
    .dropdown-menu-arrow:after {
        // bottom: -18px;
        // right: -51px;
        border-bottom-color: var(--white-color);
    }
    .dropdown-menu-arrow:before {
        // bottom: -16px;
        // right: -51px;
        opacity: 15%;
        border-bottom-color: var(--black-color);
    }
}

.navbar-collapse .nav-btn-section .nav-link.btn-set {
    padding: 6px 12px !important;
}

@media screen and (max-width: 991px) {
    .navbar-collapse .nav-btn-section button,
    .navbar-collapse .nav-btn-section .nav-link {
        display: block;
        padding: 10px 40px;
        margin: 0px !important;
    }
    .navbar-collapse .nav-btn-section .nav-link.btn-set {
        height: 47px;
        margin: 20px 10px 0px !important;
        padding: 10px 40px !important;
    }
    .navbar-collapse ul li {
        border-bottom: 1px solid var(--lightest-grey-color) !important;
    }
    .navbar-expand-lg .navbar-nav .nav-link {
        font-family: var(--default-font-family-acme) !important;
        font-style: normal !important;
        font-weight: 400 !important;
        font-size: 27px !important;
        line-height: 34px !important;
        color: var(--black-color) !important;
        display: flex !important;
        align-items: center !important;
        text-align: center !important;
        letter-spacing: 0.1em !important;
        // text-transform: uppercase !important;
    }
    .navbar-expand-lg .navbar-nav li:last-child,
    .App-header .dropdown ul li,
    .App-header-home .dropdown ul li {
        border: none !important;
    }
    .App-header .dropdown .dropdown-menu,
    .App-header-home .dropdown .dropdown-menu {
        transform: translate(-20px, 6px) !important;
    }
    .App-header .dropdown ul li,
    .App-header-home .dropdown ul li {
        padding: 0px auto !important;
    }
    .navbar-collapse .nav-btn-section button {
        margin: 20px 10px 0px !important;
    }
    .navbar-collapse ul {
        margin: 0px !important;
    }
    .navbar-collapse {
        height: 100vh;
        padding: 20px 0px 0px;
    }
    .btn-login {
        background-color: transparent !important;
    }
    .dropdown-toggle::after {
        display: inline-block !important;
        margin-left: 4px !important;
        padding-bottom: 5px !important;
    }
}

// --------------------Footer CSS------------------------------
.App-footer {
    background: var(--background-color);
    // position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 1000;
    height: 90px !important;
}

.footer-link {
    font-family: var(--font-family-inter);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin: 0px 65px;
    color: var(--gray-color-dark);
    list-style: none;
}

.footer-link:hover {
    color: var(--theme-color)
}

@media screen and (max-width: 768px) {
    .footer-link {
        font-family: var(--font-family-inter);
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        margin: 0px 12px;
        color: var(--gray-color-dark);
        list-style: none;
    }
}

  
.layout-view-content {
    padding-top: var(--header-height);
    // padding-bottom: var(--footer-height);
 
}
@media screen and (max-width: 410px) {
    .layout-view-content {
        padding-bottom: 0px !important;
    }
}  


// .header{
//     background-color: red !important;
// }

.box-outside-view {
    padding-left: 6rem;
    padding-right: 6rem;
}

@media screen and (max-width: 768px) {
    .box-outside-view {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}

.page-title {
    font-size: 3.75rem !important;
    font-family: var(--default-font-family-acme);
    line-height: 4.75rem;
    letter-spacing: 3px;
    color: var(--black-color-dark);
}

@media screen and (max-width:768px) {
    .page-title {
        font-size: 1.563rem !important;
        font-family: var(--default-font-family-acme);
        line-height: 1.938rem;
        letter-spacing: 2px;
        color: var(--black-color-dark);
    }
}

//  ----------------- Form elements -----------------
.form-group {
    font-family: var(--font-family-mulish);
    .form-label {
        font-weight: 600;
        font-size: 1rem;
        line-height: 20.08px;
        font-family: var(--font-family-mulish);
    }
    .form-control,
    .form-select {
        border: 1px solid var(--grey-light-color);
        height: auto;
        color: var(--black-color-light);
        // background: transparent;
        &:focus,
        &:active {
            outline: 0;
            border-color: var(--theme-color) !important;
            box-shadow: 0 0 0 0.2rem rgb(164 91 180/ 30%) !important;
        }
    }
    select option:checked,
    select option:hover {
        background-color: var(--background-color) !important;
    }
    .react-date-picker__wrapper {
        border: none;
    }
    .react-calendar__tile--active,
    .react-calendar__tile--hasActive {
        background-color: var(--theme-color) !important;
    }
    .react-calendar__tile--now {
        background-color: var(--background-color);
    }
    .react-date-picker__inputGroup__input {
        outline: none;
        -webkit-user-select: none;
        user-select: none;
    }
    .react-date-picker__inputGroup__year {
        width: 40px !important;
    }
}

.date-picker-cutm .react-date-picker__inputGroup__input:invalid {
    background-color: transparent !important;
}

.form-title {
    font-size: 2.75rem !important;
    font-family: var(--default-font-family-acme);
    letter-spacing: 3px;
    color: var(--black-color-dark);
    text-transform: uppercase;
}

.page-discription {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: var(--gray-color-dark);
    font-family: var(--font-family-mulish);
}

.steper-top {
    padding-top: 120px;
}

@media screen and (min-width:768px) and (max-width:991px) {
    .form-title {
        font-size: 2.2rem !important;
        letter-spacing: 2px;
    }
    .page-discription {
        font-size: 0.9rem;
        line-height: 20px;
    }
    .details div.d-flex span.long-txt, .details div.d-flex .message-family {
        font-size: 0.8rem !important;
    }
}

@media screen and (min-width:320px) and (max-width:767px) {
    .form-title {
        font-size: 1.063rem !important;
        letter-spacing: 1px;
    }
    .form-label,
    .form-control,
    .form-select {
        font-size: 0.8rem !important;
    }
    .page-discription {
        font-size: 0.625rem;
        line-height: 20px;
    }
}

@media screen and (max-width:767px) {
    .steper-top {
        padding-top: 60px;
    }
}

// ---------------------how it work
.layout-box-common {
    background-color: var(--white-color);
    padding: 65px;
    border-radius: 11px;
    
}

// ---------------btn------------------
.btn {
    line-height: 1.25;
    font-size: 0.85rem;
    border-radius: 0.75em;
    font-family: var(--font-family-mulish) !important;
    &:hover,
    &:focus,
    &:active {
        outline: 0;
        box-shadow: none !important;
    }
    &:disabled {
        cursor: not-allowed;
    }
}

.btn-theme {
    padding: 0.625rem 1.25rem !important;
    background-color: var(--theme-color) !important;
    color: var(--white-color) !important;
    border: 1px solid var(--theme-color) !important;
    font-family: var(--font-family-mulish) !important;
    &:hover,
    &:focus,
    &:active {
        background-color: var(--background-color) !important;
        color: var(--theme-color) !important;
        border: 1px solid var(--theme-color) !important;
    }
}

.btn-theme-outline {
    padding: 0.625rem 1.25rem !important;
    background-color: var(--white-color) !important;
    color: var(--theme-color) !important;
    border: 1px solid var(--theme-color) !important;
    font-family: var(--font-family-mulish) !important;
    &:hover,
    &:focus,
    &:active {
        background-color: var(--theme-color) !important;
        color: var(--white-color) !important;
        border: 1px solid var(--theme-color) !important;
    }
}

.sign-in-btn {
    padding: 4px 14px !important;
    // width: 84px;
    // height: 45px;

    background-color: var(--white-color) !important;
    // color: var(--theme-color) !important;
    border: 1px solid var(--theme-color) !important;
    font-family: var(--font-family-mulish) !important;
    color: var(--theme-color) !important;
    
    &:hover,
    &:focus,
    &:active {
        background-color: var(--theme-color) !important;
        color: var(--white-color) !important;
        border: 1px solid var(--theme-color) !important;
    }
}

@media screen and (max-width:1023px) {
    .sign-in-btn {
        padding: 0.1rem 0.1rem !important;
        background-color: var(--white-color) !important;
        color: var(--theme-color) !important;
        border: 1px solid var(--theme-color) !important;
        font-family: var(--font-family-mulish) !important;
        &:hover,
        &:focus,
        &:active {
            background-color: var(--theme-color) !important;
            color: var(--white-color) !important;
            border: 1px solid var(--theme-color) !important;
        }
    }
    .sign-in-btn .nav-link {
        padding: 10px 38px !important;
    }
}

.btn-theme-light {
    padding: 16px 28px !important;
    background: var(--theme-color-light) !important;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05) !important;
    border-radius: 8px !important;
    font-family: var(--font-family-mulish) !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 20px !important;
    color: var(--theme-color) !important;
    &:disabled {
        border-color: transparent !important;
        cursor: pointer !important;
    }
}

.email-group {
    position: relative;
    input {
        padding-left: 2.5rem !important;
    }
    .email-icon {
        position: absolute;
        top: 2.15rem;
        left: 0.7rem;
    }
}

.password-group {
    position: relative;
    input {
        padding-right: 2.5rem !important;
    }
    .password-icon {
        position: absolute;
        top: 2.15rem;
        right: 0.7rem;
        cursor: pointer;
    }
    .login-password-icon {
        position: absolute;
        top: 0.6rem;
        right: 0.7rem;
        cursor: pointer;
    }
}

.error-msg {
    color: var(--danger-color);
    font-size: 1rem;
    font-family: var(--font-family-mulish);
    font-weight: 400;
    letter-spacing: 1px;
}

.user-name {
    background-color: var(--primary-color-light);
    height: 40px;
    width: 40px;
    border-radius: 40px;
    font-family: var(--default-font-family-acme);
    text-transform: uppercase;
    color: var(--white-color);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

// -------------------checkBox------------------------------
.term-checkbox .form-check-input[type=checkbox] {
    border-radius: 0em !important;
    box-shadow: none !important;
}

.term-checkbox .form-check-input {
    width: 1.5em !important;
    height: 1.5em !important;
    margin-top: -0em !important;
    // background-color: rgba(0, 0, 0, 0.25) !important;
    border: 1px solid var(--black-color) !important;
}

.term-checkbox .form-check-input {
    cursor: pointer !important;
    &:checked {
        background-color: var(--theme-color) !important;
        border-color: var(--theme-color) !important;
    }
}

.term-checkbox .term-text {
    text-decoration: none !important;
}

.term-checkbox .form-check-label {
    font-size: 1rem !important;
}

@media screen and (max-width: 768px) {
    .term-checkbox .form-check-label {
        font-size: 0.75rem !important;
    }
}

// ------------------------- Jobs CSS ------------------------------
.job-page .sub-title {
    font-family: var(--default-font-family-acme);
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 61px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: var(--black-color-dark);
}

.job-page .details {
    background: var(--theme-color) !important;
    border-radius: 10px 10px 0px 0px !important;
    padding: 15px 20px !important;
    font-family: var(--default-font-family-acme) !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: medium !important;
    line-height: 25px !important;
    letter-spacing: 0.1em !important;
    text-transform: uppercase !important;
    color: var(--white-color) !important;
    & a {
        font-family: var(--font-family-mulish) !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: medium !important;
        line-height: 20px !important;
        text-transform: capitalize !important;
        text-decoration: underline!important;
        color:#00bfff !important;
    }
}

.job-page .sub-details {
    background: var(--theme-color-light) !important;
    margin-bottom: 28px !important;
    border-radius: 0px 0px 10px 10px !important;
    padding: 15px 20px !important;
    font-family: var(--font-family-mulish) !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 20px !important;
    color: var(--black-color-dark) !important;
}

.job-page .link-mobile,
.job-page .date-mobile {
    display: none !important;
}

.job-page .link-desktop,
.job-page .date-desktop {
    display: flex !important;
    align-items: center !important;
}

.job-page .sub-details img {
    height: 20px !important;
    width: 20px !important;
}

.details div.d-flex span {
    display: flex !important;
    align-items: center !important;
}

@media screen and (max-width: 991px) {
    .details div {
        margin: 2px 0px !important;
    }
    .job-page .link-desktop,
    .job-page .date-desktop {
        display: none !important;
    }
    .job-page .link-mobile,
    .job-page .date-mobile {
        display: flex !important;
        align-items: center !important;
    }
    .job-page .date-mobile {
        justify-content: end !important;
    }
    .job-page .link-mobile {
        align-items: center !important;
    }
    .details div.d-flex span {
        font-size: 12px !important;
        line-height: 17px !important;
        display: flex !important;
        align-items: center !important;
    }
    .job-page .sub-details {
        padding: 15px 10px !important;
        justify-content: space-between !important;
        flex-flow: wrap;
    }
}

@media screen and (max-width: 375px) {
    .job-page .details {
        font-size: 4vw !important;
        line-height: 20px !important;
    }
    .details div.d-flex span {
        font-size: 10px !important;
        line-height: 13px !important;
        display: flex !important;
        align-items: center !important;
    }
}

@media screen and (max-width: 420px) {
    .details div.d-flex span.long-txt {
        font-size: 0.8rem !important;
    }
}

@media screen and (max-width: 540px) {
    .job-page .sub-details {
        font-size: 3vw !important;
        line-height: 20px !important;
        flex-wrap: wrap;
    }
    .job-page .sub-details img {
        width: 16px !important;
        height: 16px !important;
    }
    .job-page .sub-title {
        font-size: 9vw !important;
    }
    .details div.d-flex a {
        font-size: 12px !important;
    }   
}

.job-page {
    .btn-pagination,
    .paginate-btn ul li button {
        background: transparent;
        border: none;
        box-shadow: none;
        border-radius: 50px;
        color: var(--theme-color);
        font-family: var(--font-family-inter);
        font-style: normal;
        font-weight: 600;
        font-size: 1vw;
        line-height: 18px;
        width: max-content;
        margin: 0px 5px;
        &:hover,
        &:hover i,
        &:focus,
        &:focus i,
        &:active,
        &:active i,
        &.Mui-selected,
        &.active {
            background: var(--theme-color) !important;
            border: 0px !important;
            box-shadow: none !important;
            color: var(--white-color) !important;
            border-radius: 50px !important;
        }
        &:disabled {
            background: transparent !important;
            color: var(--black-color-light) !important;
            opacity: 40% !important;
            cursor: none !important;
            &:hover,
            &:hover i,
            &:focus,
            &:focus i,
            &:active,
            &.Mui-selected,
            &:active i,
            &.active {
                background: transparent !important;
                color: var(--black-color-dark) !important;
                box-shadow: none !important;
                border: 0px !important;
            }
        }
        i {
            margin: 0px 7px;
        }
    }
    .paginate-btn ul li .MuiPaginationItem-ellipsis {
        pointer-events: none !important;
    }
}

@media screen and (max-width: 1024px) {
    .job-page {
        .btn-pagination,
        .paginate-btn ul li button {
            font-size: 0.75rem;
        }
        .pagination-number {
            font-size: 1.3vw;
        }
    }
}

@media screen and (max-width: 768px) {
    .job-page {
        .btn-pagination,
        .paginate-btn ul li button {
            font-size: 0.75rem;
        }
        .pagination-number {
            font-size: 1.8vw;
        }
    }
}

@media screen and (max-width: 375px) {
    .job-page {
        .btn-pagination,
        .paginate-btn ul li button {
            font-size: 0.75rem;
            margin: 0px 3px !important;
        }
    }
    .pagination-page-number p {
        font-size: .7rem;
    }
}

// --------------------Terms CSS---start-----------------------------
.terms-page h1 {
    font-family: var(--default-font-family-acme);
    font-style: normal;
    font-weight: 400;
    font-size: 4vw;
    line-height: 76px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    margin-top: 30px;
    color: var(--black-color-dark);
}

.terms-page .box-table {
    padding: 82px 131px 126px 74px;
    background-color: var(--white-color);
    box-shadow: 0px 20px 24px -4px rgba(237, 222, 240, 0.6);
    border-radius: 10px;
}

.terms-page .box-table .sub-title {
    font-family: var(--default-font-family-acme);
    font-style: normal;
    font-weight: 400;
    font-size: 3vw;
    line-height: 61px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: var(--black-color-dark);
}

.terms-page .box-table .term1 p {
    // font-family: var(--font-family-mulish);
    // font-style: normal;
   // font-weight: 700;
    // font-size: 16px;
    line-height: 20px;
    padding: 15px 0px 0px 31px;
    margin-bottom: 0px;
    // color: var(--black-color);
    text-align: justify;
    &:last-child {
        margin-bottom: 97px;
    }
}

.terms-page .box-table .term2 p {
//  font-family: var(--font-family-mulish);
    // font-style: normal;
    //font-family: var(--default-font-family-acme);
   // font-weight: 700;
    // font-size: 16px;
    line-height: 20px;
    margin-bottom: 0px;
    padding: 15px 0px 0px 31px;
    // color: var(--black-color);
    text-align: justify;
}

@media screen and (max-width: 787px) {
    .terms-page .box-table {
        margin-top: 22px;
        padding: 20px 32px 20px 15px;
    }
}

@media screen and (max-width: 767px) {
    .terms-page .box-table .sub-title {
        // font-size: 0.75rem;
        line-height: 15px;
       // font-weight: 700;
    }
    .terms-page .box-table .term1 p {
        // font-family: var(--font-family-mulish);
        // font-style: normal;
       //  font-weight: 700;
        // font-size: 10px;
        line-height: 20px;
        padding: 0px 0px 0px 0px;
        margin-bottom: 0px;
        // color: var(--black-color);
        &:last-child {
            margin-bottom: 20px;
        }
    }
    .terms-page .box-table .term2 p {
        // font-family: var(--font-family-mulish);
        // font-style: normal;
        //font-weight: 700;
        // font-size: 10px;
        line-height: 20px;
        margin-bottom: 0px;
        padding: 0px 0px 0px 0px;
        // color: var(--black-color);
    }
}

// --------------------Terms CSS---end-----------------------------
// -------------------------------phone input cstm start css --------------------------------
.cstm-input .react-tel-input .form-control {
    width: 100% !important;
    border: 1px solid var(--grey-light-color) !important;
}


/*General Classes*/

.cursor-pointer {
    cursor: pointer !important;
}

.btn-custom-disabled {
    opacity: 0.7;
    border: none !important;
}

.btn-loader {
    width: 190.2px !important;
    &:focus,
    &:hover {
        cursor: not-allowed !important;
    }
}

.btn-payment-loader {
    width: 332px !important;
    &:focus,
    &:hover {
        cursor: not-allowed !important;
    }
}

// -------------------------------phone input cstm end  css ---------------------------------
//---------------------------Remove icon style Start-------------------------------------------
.remove-icon-view {
    width: 26px;
    height: 26px;
    background-color: var(--theme-color);
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.remove-icon-size {
    width: 15px;
    height: 15px;
}

//---------------------------Remove icon style end---------------------------------------------
//-------------------------------------page subTitle and Description start-----------------------------
.page-subtitle {
    font-family: var(--default-font-family-acme) !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 3rem !important;
    line-height: 61px !important;
    letter-spacing: 0.1em !important;
    text-transform: uppercase !important;
}

.page-sub-desc {
    font-family: var(--font-family-mulish);
    font-style: normal;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 30px;
    text-align: center;
    color: var(--gray-color-dark);
}

@media screen and (max-width: 768px) {
    .page-subtitle {
        font-family: var(--default-font-family-acme) !important;
        font-style: normal !important;
        font-weight: 400 !important;
        font-size: 1.063rem !important;
        line-height: 15px !important;
        letter-spacing: 0.1em !important;
        text-transform: uppercase !important;
    }
    .page-sub-desc {
        font-family: var(--font-family-mulish);
        font-style: normal;
        font-weight: 600;
        font-size: 0.75rem;
        line-height: 20px;
        text-align: center;
        color: var(--gray-color-dark);
    }
}

@media screen and (max-width: 410px) {
    .page-title {
        font-weight: 400 !important;
        font-size: 1.75rem !important;
        line-height: 32px !important;
    }
}

//--------------------------------------page subTitle and Description End------------------------------
/////-------------------------------modal css-------------------------------------------------start
.filter-container {
    width: fit-content !important;
}

.delete-modal {
    .modal-header img {
        height: fit-content !important;
        padding: 0px 20px !important;
    }
    .modal-body {
        font-family: var(--default-font-family-acme) !important;
        font-style: normal !important;
        font-weight: 400 !important;
        font-size: 28px !important;
        line-height: 34px !important;
        text-align: center !important;
        padding: 37px 29px !important;
        text-transform: unset !important;
        color: var(--black-color-dark) !important;
        &.sendemail {
            font-family: var(--default-font-family-acme) !important;
            font-style: normal !important;
            font-weight: 400 !important;
            font-size: 18px !important;
            line-height: 24px !important;
            text-align: center !important;
            padding: 27px 2px 2px !important;
            text-transform: unset !important;
            color: var(--black-color-dark) !important;
        }
        &.padding-small {
            padding: 15px 22px !important;
        }
        &.sendemail-body {
            padding: 27px 29px 12px !important;
        }
        .tick-animation {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .checkmark__circle {
            stroke-dasharray: 166;
            stroke-dashoffset: 166;
            stroke-width: 2;
            stroke-miterlimit: 10;
            stroke: var(--theme-color);
            fill: var(--white-color);
            animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards
        }
        .checkmark {
            width: 76px;
            height: 76px;
            border-radius: 50%;
            display: block;
            stroke-width: 2;
            stroke: var(--theme-color);
            stroke-miterlimit: 10;
            box-shadow: inset 0px 0px 0px var(--theme-color);
            animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both
        }
        .checkmark__check {
            transform-origin: 50% 50%;
            stroke-dasharray: 48;
            stroke-dashoffset: 48;
            animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards
        }
        @keyframes stroke {
            100% {
                stroke-dashoffset: 0
            }
        }
        @keyframes scale {
            0%,
            100% {
                transform: none
            }
            50% {
                transform: scale3d(1.1, 1.1, 1)
            }
        }
        @keyframes fill {
            100% {
                box-shadow: inset 0px 0px 0px 30px var(--theme-color)
            }
        }
    }
    .modal-footer {
        display: flex !important;
        // justify-content: center !important;
        padding-top: 10px !important;
        border: 0px !important
    }
    .btn-yes {
        background: var(--theme-color) !important;
        border-radius: 7px !important;
        font-family: var(--font-family-mulish) !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 16px !important;
        line-height: 20px !important;
        padding: 10px 28px !important;
        text-align: center !important;
        border: 1px solid var(--theme-color) !important;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05) !important;
        border-radius: 8px !important;
        color: var(--white-color) !important;
        &:hover,
        &:focus {
            background: var(--darkest-grey-color) !important;
            color: var(--theme-color) !important;
            border: 1px solid var(--theme-color) !important;
            box-shadow: none !important;
        }
    }
    .btn-yes1 {
        // background: var(--theme-color) !important;
        background-color: rgb(227, 221, 221);
        border-radius: 7px !important;
        font-family: var(--font-family-mulish) !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 16px !important;
        line-height: 20px !important;
        padding: 10px 28px !important;
        text-align: center !important;
        border: 1px solid rgb(227, 221, 221) !important;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05) !important;
        border-radius: 8px !important;
        color: var(--white-color) !important;
        &:hover,
        &:focus {
            background: var(--darkest-grey-color) !important;
            color: var(--theme-color) !important;
            border: 1px solid var(--theme-color) !important;
            box-shadow: none !important;
        }
    }
    .btn-no {
        background: rgb(222, 217, 217) !important;
        border-radius: 7px !important;
        font-family: var(--font-family-mulish) !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 16px !important;
        line-height: 20px !important;
        padding: 10px 28px !important;
        text-align: center !important;
        border: 1px solid rgb(222, 217, 217) !important;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05) !important;
        border-radius: 8px !important;
        color: var(--white-color) !important;
        &:hover,
        &:focus {
            background: #ebebeb77 !important;
            color: var(--theme-color) !important;
            border: 1px solid var(--theme-color) !important;
            box-shadow: none !important;
        }
    }
}

.modal-body p.forgot-emailid {
    font-family: var(--font-family-mulish) !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 18px !important;
    text-align: center !important;
    padding: 7px 2px 0px !important;
    text-transform: unset !important;
    color: var(--black-color-dark) !important;
}

@media screen and (max-width: 580px) {
    .delete-modal .modal-body {
        font-size: 6.1vw !important;
        line-height: 130% !important;
        padding: 5px 12px !important;
    }
    .delete-modal {
        .delete-modal .modal-header img {
            padding: 0px 5px !important;
        }
        .modal-footer {
            padding: 15px 10px !important;
        }
        .modal-footer .btn-yes,
        .modal-footer .btn-cancel {
            font-size: 4.4vw !important;
            padding: 12px 20px !important;
        }
    }
}

/////-------------------------------modal css-------------------------------------------------end
//================================custome radio btn theme- start----------------------------------------
.ctsm-radio .form-check {
    display: flex !important;
    align-items: center !important;
}

.ctsm-radio .form-check-input[type=radio] {
    border: 4px solid var(--theme-color) !important;
    cursor: pointer !important;
    width: 23px !important;
    height: 23px !important;
}

.ctsm-radio .form-check-label {
    width: 100% !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 8.5px 28px !important;
    // margin: 0px 0px 0px 61px !important;
    background: var(--theme-color) !important;
    border: 1px solid var(--darkest-blue-color) !important;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05) !important;
    border-radius: 8px !important;
    font-family: var(--font-family-mulish) !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 1rem !important;
    line-height: 1.25rem !important;
    color: var(--white-color) !important;
}

.ctsm-radio .form-check-input[type=radio]:checked {
    background-color: var(--theme-color) !important;
    background-image: none !important;
}

.ctsm-radio .form-check-input:focus {
    box-shadow: none !important;
}

@media screen and (max-width: 992px) {
    .ctsm-radio .form-check-label {
        font-size: 0.875rem !important;
        margin-left: 14px !important;
    }
}

@media screen and (max-width: 519px) {
    .ctsm-radio .form-check-label {
        font-size: 2.4vw !important;
    }
    @media screen and (max-width: 410px) {
        .ctsm-radio .form-check-label {
            font-size: 2.5vw !important;
            line-height: 150% !important;
            margin-left: 5px !important;
        }
    }
}

//================================custome radio btn theme- end ----------------------------------------
//--------------------------------------change subscription page style start------------------------------------
.scription-modify-desc {
    font-family: var(--default-font-family-acme) !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 1.75rem !important;
    line-height: 32px !important;
    text-align: center !important;
    letter-spacing: 0.1em !important;
    text-transform: uppercase !important;
    color: var(--black-color-dark) !important;
    span {
        color: var(--light-green-color) !important;
    }
}

@media screen and (max-width: 768px) {
    .scription-modify-desc {
        font-family: var(--default-font-family-acme) !important;
        font-style: normal !important;
        font-weight: 400 !important;
        font-size: 0.75rem !important;
        line-height: 20px !important;
        text-align: center !important;
        letter-spacing: 0.1em !important;
        text-transform: uppercase !important;
        color: var(--black-color-dark) !important;
        span {
            color: var(--light-green-color) !important;
        }
    }
}

//--------------------------------------change subscription page style- end-----------------------------------\
//--------------------------------- toastify icon
.Toastify__toast-icon svg path {
    fill: var(--theme-color) !important;
}

// --------------------------------- Spinner style
.bsspin {
    color: var(--theme-color) !important;
}

// profile img
.profile-pic {
    width: 188px;
    height: 176px;
    border-radius: 50%;
}

@media screen and (max-width: 993px) {
    .profile-pic {
        width: 118px;
        height: 106px;
    }
}

//-------------------- jobe kid for kid info-------------------------------
@media screen and (max-width: 390px) {
    .girl-boy-select .form-group {
        .form-control,
        .form-select {
            font-size: 8px !important;
        }
    }
}

.date-picker-cutm .react-datepicker__triangle {
    left: -30px !important;
}

.date-picker-cutm {
    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--selected,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--selected,
    .react-datepicker__year-text--in-selecting-range,
    .react-datepicker__year-text--in-range,
    .react-datepicker__day--keyboard-selected,
    .react-datepicker__month-text--keyboard-selected,
    .react-datepicker__quarter-text--keyboard-selected,
    .react-datepicker__time-list-item--selected,
    .react-datepicker__year-text--keyboard-selected .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
        background-color: var(--theme-color) !important;
        color: var(--white-color) !important;
    }
    .react-datepicker__header {
        background-color: var(--theme-color-light) !important;
    }
    .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
        border-top: none !important;
        border-bottom-color: var(--theme-color-light) !important;
    }
    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
        padding: 5px 0px !important;
    }
    .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-year-read-view--down-arrow,
    .react-datepicker__navigation-icon::before {
        border-color: var(--grey-light-color) !important;
        height: 8px;
        width: 8px;
        top: 4px;
    }
    .react-datepicker__navigation--years-upcoming {
        background-image: url("../images/up-downicon.svg") !important;
        background-repeat: no-repeat !important;
        top: 14px !important;
        background: top;
    }
    .react-datepicker__navigation--years-previous,
    .react-datepicker__navigation--next {
        background-image: url("../images/up-downicon.svg") !important;
        background-repeat: no-repeat !important;
        rotate: 180deg !important;
        top: -14px !important;
        background: top;
    }
    .react-datepicker__navigation--next {
        top: 0px !important;
        rotate: 90deg !important;
        background: center;
    }
    .react-datepicker__navigation--previous {
        background-image: url("../images/up-downicon.svg") !important;
        background-repeat: no-repeat !important;
        top: 0px !important;
        rotate: -90deg !important;
        background: center;
    }
    .react-datepicker__navigation-icon::before {
        display: none !important;
    }
    .react-datepicker__year-option:hover {
        fill: var(--theme-color-light) !important;
    }
    .react-datepicker {
        display: flex !important;
        font-family: var(--font-family-mulish) !important;
    }
    .react-datepicker__header__dropdown {
        display: flex !important;
        justify-content: center !important;
    }
    .react-datepicker__month-dropdown-container,
    .react-datepicker__year-dropdown-container {
        display: flex !important;
        margin: 0px 30px !important;
    }
    .react-datepicker__year-dropdown {
        top: 55px !important;
        left: 40% !important;
    }
    .react-datepicker__month-dropdown {
        top: 55px !important;
        left: 15% !important;
    }
    .react-datepicker__year-read-view,
    .react-datepicker__month-read-view {
        visibility: visible !important;
    }
    .react-datepicker__month .react-datepicker__month-text {
        padding: 5px !important;
    }
}

//-----------------------conformation mail page style--------------
.conf-mail-title {
    color: var( --gray-color-dark);
    font-size: 23px;
}

.conf-mail-value {
    font-size: 14px;
}

// pending verfication screen
.verifyemail {
    height: 100px;
    width: 200px;
}

.account-verf-text {
    font-size: 2rem;
    font-family: var(--font-family-mulish);
}

@media screen and (max-width: 768px) {
    .account-verf-text {
        font-size: 0.75rem;
    }
}

.heart-img {
    height: 20px !important;
    width: 30px !important;
}
.unorder-list-circle{
    list-style-type:circle;
}
.table-link a{
    color: #68BBE3 !important;
    text-decoration: underline;
}
.web-logo{
    width:260px;
    height: 79px;
    // margin-left: 28px;
}

// New Changes

.header-changes{
    height: 115px !important;
    background-color: white !important;
  }

  .see-how-it-works-text{
    font-size: 46px !important;
    color: var(--black-color) !important;
    font-weight: 400 !important;
  }

  .workVideo{
    width: 922px;
    height: 516px ;
    margin: auto !important;
}
  @media screen and (max-width: 550px) {
    .our-layout{
        padding: 32px 30px !important;
    }

    .question{
        margin-top: 0  !important;
    }

    .question h4{
        margin-bottom: 0 !important;
    }
    .see-how-it-works-text{
        font-size: 24px !important;
      }
      .layout-view-content{
        margin-top: -37px;
      }
      .header-changes{
        height: 90px !important;
      }
    // .parent-layout{
    //     margin: 0px !important;
    //     padding: 0px !important;
    // }  
    .workVideo{
        width: 100% !important;
        height: 8% !important;
        margin: -8px !important;
    }
    // .web-logo{
    //     width:213px;
    //     height: 65px;
    //     margin-left: 0px;
    // }

    .work_tittle{
        font-size:28px !important;
        color: #000000 !important;
        font-weight: 400 !important;
        line-height: 58.24px !important;
        margin: 40px 0 20px !important 
    }
  }

  .our-layout{
    padding: 80px 72px;
    background-color: var(--white-color);
    border-radius: 10px;
  }

  
  


@media sceren and (min-width:767px){
.work_tittle{
    font-size: 46px !important;
    color: #000000 !important;
    font-weight: 400 !important;
    line-height: 58.24px !important;
    margin: 82px 0 37px !important 
}
}

@media (max-width: 765px) and (min-width: 500px) {
    .workVideo{
      width: auto !important;
    }
    .how-see-layout{
      margin-left: 55px !important;
      margin-right: -54px !important;
    }
    .our-layout{
      padding: 80px 63px;
    }
  }

@media screen and (min-width:500px) and (max-width:765px){
    .how-see-layout{
        margin-left: 9% !important;
        margin-right: 9% !important;
    }

    .workVideo{
        height: auto !important;
    }
}  

.box-shadow-frequency-question{
    box-shadow: 0px 2px 10px 0px #00000040;
}

@media screen and (max-width: 1900px) and (min-width: 576px) {
    .see-how-it-works-text{
        margin-top: 47px;
    }
    .frequeny-box-padding-top{
        margin-top: 85px;
    }
  }
  