// ----------------------------Message center
.chatsection {
    border-radius: 10px !important;
    .fixed-header-chat {
        // position: absolute;
        // width: auto;
        // z-index: 100;
        // border-radius: 10px 0px 0px 0px;
        // background-color: var(--white-medium-color);
    }
    .name-chatsection {
        width: 100%;
        background-color: var(--white-medium-color) !important;
        border-radius: 10px 0px 0px 10px;
        overflow-y: hidden;
        max-height: 100vh;
    }
    .MuiOutlinedInput-notchedOutline {
        background-color: var(--white-color);
        border-radius: 8px;
    }
    .MuiOutlinedInput-input {
        padding: 10px 14px;
        border: 1px solid var(--grey-light-color);
        height: auto;
        color: var(--black-color-light);
        border-radius: 10px;
        // background: transparent;
        &:focus,
        &:active {
            outline: 0;
            border-color: var(--theme-color) !important;
        }
    }
    hr.divider-chat.MuiDivider-root {
        border: 1px solid var(--gray-color);
        margin: 5px 0px;
        opacity: .3;
    }
    hr.message-devider {
        border: 1px solid var(--white-medium-color);
        opacity: 1;
        margin-top: .6rem !important;
    }
    .title-sec-message {
        font-family: var(--default-font-family-acme);
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 25px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: var(--black-color-dark);
        margin-bottom: 1px;
    }
    .name-chatsection ul li a {
        text-decoration: none;
    }
    .sec-chat-message {
        display: block;
        font-family: var(--font-family-mulish) !important;
        font-weight: 600 !important;
        font-size: 12px !important;
        line-height: 15px !important;
        color: var(--black-color-dark);
        margin-bottom: 1px;
        max-width: 140px;
    }
}

.message-box {
    background: var(--background-color) !important;
    border-radius: 0px 10px 10px 0px;
    max-height: 100vh;
    overflow: hidden;
}

.messagebox-title {
    font-family: var(--default-font-family-acme);
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 38px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: var(--black-color-dark);
}

.sec-messagebox-title {
    flex: none !important;
}

.sec-messagebox-title {
    display: block;
    font-family: var(--font-family-mulish) !important;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: var(--black-color-dark);
}

.message-box .link-job {
    font-family: var(--font-family-mulish);
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    text-decoration-line: underline;
    color: var(--theme-color);
    padding-left: 8px;
    text-decoration-color: var(--theme-color);
}

.messageArea {
    height: 71vh;
    overflow-y: auto;
}

.message-typer {
    padding: 10px 20px;
}

@media screen and (max-width: 599px) {
    .chatsection .name-chatsection {
        padding: 10px 0px;
        border-radius: 10px;
    }
    .message-box {
        border-radius: 10px;
        width: 100%;
    }
}

// -----------------------bschat
#chat3 .form-control {
    border-color: transparent;
}

#chat3 .form-control:focus {
    border-color: transparent;
    box-shadow: inset 0px 0px 0px 1px transparent;
}

.badge-dot {
    border-radius: 50%;
    height: 10px;
    width: 10px;
    margin-left: 2.9rem;
    margin-top: -.75rem;
}

.send-mess {
    background-color: var(--white-medium-color);
    margin-left: auto;
}

.reciver-mess {
    margin-left: auto !important;
    background-color: var(--white-color);
    color: var(--black-color-dark) !important;
}

.text-muted.type-mess {
    position: absolute;
    width: inherit;
    background-color: var(--background-color);
    bottom: 0px;
    z-index: 100;
}

.message-scroll {
    overflow-y: scroll;
    height: 533px;
    overflow-x: hidden;
}

.message-scroll::-webkit-scrollbar {
    width: .2rem;
}

.message-scroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.message-scroll::-webkit-scrollbar-thumb {
    background-color: darkgrey;
}

@media screen and (max-width: 1199px) {
    .chatsection .name-chatsection,
    .message-box {
        max-height: 100%;
    }
    .send-mess {
        width: 80%;
    }
}

@media screen and (max-width: 767px) {
    .send-mess {
        width: 83%;
    }
    .chatsection .name-chatsection {
        border-radius: 10px;
    }
}

.message-time {
    font-size: 12px;
    color: var(--black-color-light);
    font-weight: 700;
    display: block;
    width: fit-content;
}

// .time-right-mess {
//     font-size: 12px;
//     color: var(--black-color-light);
//     font-weight: 700;
//     display: block;
//     width: fit-content;
//     margin-right: auto;
// }
.message-day {
    display: flex;
    justify-content: center;
    & p {
        padding: 5px 7px;
        font-size: 10px;
        color: var(--black-color-light);
        background: var(--bg-light-grey-color);
        border: 1px solid var(--bg-light-grey-color);
        border-radius: 5px;
        font-weight: 700;
        margin-bottom: 0px;
    }
}

.maxheight-padding {
    overflow-y: scroll;
    max-height: 405px;
    padding: 0px 10px;
    margin-bottom: 60px;
}

.chatuser-img {
    width: 45px;
    height: 100%;
}

.bg-backround-color {
    background-color: var(--background-color);
}

.height-width-30 {
    height: 30px;
    width: 30px;
}

.padding-20-44 {
    padding: 20px 44px;
}

.padding-50-44 {
    padding: 50px 44px;
}

.person-name.active,
.person-name:focus,
.person-name:visited {
    background-color: var(--theme-color) !important;
    & div p {
        color: var(--white-color) !important;
    }
}

.no-messages-placeholder {
    font-family: var(--default-font-family-acme) !important;
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 24px;
    color: var(--black-color-dark);
    opacity: .9;
}

.chathead-img {
    height: 35px;
    width: 35px;
}

@media screen and (max-width: 425px) {
    .messagebox-title {
        font-size: 5.5vw;
        line-height: normal;
    }
    .chat-input-width {
        width: 65%;
    }
}

.unread-message {
    border-radius: 50%;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--theme-color);
}

// .EmojiPickerReact {
//     position: absolute !important;
//     margin: 55px 0px !important;
//     max-height: 300px !important;
//     overflow-y: scroll !important;
//     z-index: 0 !important;
// }
// .desktop-chat {
//     display: block !important;
// }
// .mobile-chat {
//     display: none !important;
// }
// @media screen and (max-width: 767px) {
//     .desktop-chat {
//         display: none !important;
//     }
//     .mobile-chat {
//         display: block !important;
//     }
// }