@import './variable.scss';
.table-page .sub-title {
    font-family: var(--default-font-family-acme);
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 61px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: var(--black-color-dark);
}

.table-page .details {
    background: var(--theme-color) !important;
    border-radius: 10px 10px 0px 0px !important;
    padding: 15px 20px !important;
    font-family: var(--default-font-family-acme) !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: medium !important;
    line-height: 25px !important;
    letter-spacing: 0.1em !important;
    text-transform: uppercase !important;
    color: var(--white-color) !important;
    & a {
        font-family: var(--font-family-mulish) !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: medium !important;
        line-height: 20px !important;
        text-transform: capitalize !important;
        text-decoration: underline!important;
        color:#00bfff !important;
    }
    & .sub-details-table {
        font-family: var(--font-family-mulish) !important;
        font-weight: 300 !important;
        font-style: normal !important;
    }
}

.table-page .sub-details {
    background: var(--theme-color-light) !important;
    margin-bottom: 28px !important;
    border-radius: 0px 0px 10px 10px !important;
    padding: 15px 20px !important;
    font-family: var(--font-family-mulish) !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 20px !important;
    color: var(--black-color-dark) !important;
}

.table-page .link-mobile,
.table-page .date-mobile {
    display: none !important;
}

.table-page .link-desktop,
.table-page .date-desktop {
    display: flex !important;
    align-items: center !important;
}

.table-page .sub-details img {
    height: 20px !important;
    width: 20px !important;
}

.details div.d-flex span {
    display: flex !important;
    align-items: center !important;
}

.details div.d-flex span.long-txt,
.details div.d-flex .message-family {
    font-family: var(--font-family-mulish) !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px;
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
    .details div.d-flex span.long-txt,
    .details div.d-flex .message-family {
        font-size: 1.1vw !important;
    }
}

@media screen and (max-width: 551px) {
    .details div.d-flex span.long-txt,
    .details div.d-flex .message-family {
        font-size: 0.8rem !important;
    }
}

@media screen and (max-width: 991px) {
    .details div {
        margin: 2px 0px !important;
    }
    .table-page .link-desktop,
    .table-page .date-desktop {
        display: none !important;
    }
    .table-page .link-mobile,
    .table-page .date-mobile {
        display: flex !important;
        align-items: center !important;
    }
    .table-page .date-mobile {
        justify-content: end !important;
    }
    .table-page .link-mobile {
        align-items: center !important;
    }
    .details div.d-flex span {
        font-size: 12px !important;
        line-height: 17px !important;
        display: flex !important;
        align-items: center !important;
    }
    .table-page .sub-details {
        padding: 15px 10px !important;
        justify-content: space-between !important;
    }
}

@media screen and (max-width: 375px) {
    .table-page .details {
        font-size: 4vw !important;
        line-height: 20px !important;
    }
    .details div.d-flex span {
        font-size: 10px !important;
        line-height: 13px !important;
        display: flex !important;
        align-items: center !important;
    }
}

@media screen and (max-width: 420px) {
    .details div.d-flex span.long-txt,
    .details div.d-flex .message-family {
        font-size: 0.8rem !important;
    }
}

@media screen and (max-width: 380px) {
    .details div.d-flex span.long-txt {
        font-size: 0.8rem !important;
    }
}

@media screen and (max-width: 540px) {
    .table-page .sub-details {
        font-size: 3vw !important;
        line-height: 20px !important;
    }
    .table-page .sub-details img {
        width: 16px !important;
        height: 16px !important;
    }
    .table-page .sub-title {
        font-size: 9vw !important;
    }
    .details div.d-flex a {
        font-size: 12px !important;
    }
}

//   @media screen and (max-width: 420px){
// .details div.d-flex span.long-txt {
//     font-size: 2.5vw !important;
// }
//   }
//   @media screen and (max-width: 344px){
//   .table-page .details {
//     font-size: 3vw !important;
//     line-height: 20px !important;
// }
//   }
.profile-heading {
    font-family: var(--font-family-mulish) !important;
    text-transform: capitalize;
    font-size: medium;
}

.text-transorm-none {
    text-transform: none !important;
}

