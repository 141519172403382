.footer {
  background-color: var(--theme-color);
}

.footer-nav {
  list-style: none;
  font-size: 16px !important;
  line-height: 19.36px;
  padding: 43px 35px;
  color: var(--white-color);
  // --font-family-inter: "Inter", sans-serif !important;
  font-family: var(--font-family-inter);
  font-weight: 600;
}

@media screen and (max-width: 1300px) {
  .footer {
    padding: 10px 0 10px 0 !important;
  }

  .footer-nav {
    padding: 7px !important;
  }
}



@media screen and (max-width: 992px) {
  .footer-logo {
    text-align: center;
    padding: 20px 0px 0px
  }

  .footer-nav {
    text-align: center;
  }

  .social-icon {
    display: none;
  }
}

@media screen and (max-width:636px) {
  .footer-nav {
    font-size: 13px;
  }
}

@media screen and (max-width:680px) {
 

  .footer-links-container {
    display: inline-block !important;
    text-align: center;
    padding: 0px 0px 0px 5px !important;
  }

  .footer-nav {
    font-size: 16px;
    align-items: center;
    padding:10px 18px 10px 0px !important;
    display: inline-block;
  }

  .side_line {
    border-right: 1px solid var(--white-color);
    padding: 2px 15px 2px 2px !important;
  }
}

@media screen and (max-width: 912px) and (min-width: 700px) {
  .margin-top-footer{
    margin-top: 20px;
  }
  .footer-spacing{
    margin: 0px 11px;
  }
}